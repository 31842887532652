import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { useCallback, useMemo } from "react";
import { localeNumberFormat } from "utils/formatters/localeNumberFormat";
import { getCurrentGlobalPreferences } from "utils/getCurrentGlobalPreferences";
import { RegionType } from "api/query/models";

export function useLocaleNumberFormatter() {
  const { globalPreference } = useGlobalPreferenceContext();

  const numberFormatter = useCallback(
    ({
      value,
      region,
      additionalOptions,
    }: {
      value: any;
      region?: RegionType;
      additionalOptions?: any; //Parameters<typeof Intl.NumberFormat>[1];
    }) => {
      return localeNumberFormat(
        value,
        region ?? globalPreference?.regionType ?? getCurrentGlobalPreferences().regionType,
        additionalOptions,
      );
    },
    [globalPreference?.regionType],
  );

  const numberFormatterWithDash = useCallback(
    (data: {
      value: any;
      region?: RegionType;
      additionalOptions?: any; //Parameters<typeof Intl.NumberFormat>[1];
    }) => {
      const result = numberFormatter(data);

      if (result === "NaN") {
        return "-";
      }

      return result;
    },
    [numberFormatter],
  );

  return useMemo(
    () => ({ numberFormatter, numberFormatterWithDash }) as const,
    [numberFormatter, numberFormatterWithDash],
  );
}
