import { useMachine } from "@xstate/react";
import { cloneDeep, isEqual } from "lodash-es";
import { UserRoleType, UserStatusType } from "models/PersonModels";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from "react";
import { getImageSrc } from "utils/converters/getImageSrc";
import { AvatarAction, AvatarState } from "utils/hooks/useAvatarState";
import { makeDetailsPageStateMachine } from "utils/machines/pages/details/makeDetailsPageStateMachine";

interface Avatar {
  avatarState: AvatarState;
  dispatchAvatarAction: React.Dispatch<AvatarAction>;
  uploadAvatar: () => Promise<void>;
  deleteAvatar: () => Promise<void>;
}

interface PersonModel {
  status: UserStatusType;
  firstName: string;
  lastName: string;
  role?: UserRoleType;
}

export function useFormHandlers<
  ResponseModel extends PersonModel,
  EntityModel,
  // Events extends DetailsPageServiceList<any, any, any> = any,
>(
  detailsService: ReturnType<
    typeof useMachine<ReturnType<typeof makeDetailsPageStateMachine<ResponseModel, EntityModel>>>
  >,
  avatarApi: Avatar,
  tempEntityApi: [any, Dispatch<SetStateAction<any>>],
  getFieldsReady: (tempEntityState: any) => boolean,
  handlerCallbacks?: {
    submitAvatar: (newImage: string | undefined) => void;
  },
) {
  const [state, send, actor] = detailsService;
  const [tempEntityState, setTempEntityState] = tempEntityApi;
  const { avatarState, dispatchAvatarAction, uploadAvatar, deleteAvatar } = avatarApi;

  const saveDisabled = useMemo(
    () =>
      state.matches("entity.saving") ||
      getFieldsReady(tempEntityState) === false ||
      avatarState.loading ||
      (isEqual(state.context?.data, tempEntityState) && !!avatarState.newImage === false),
    [avatarState.loading, avatarState.newImage, getFieldsReady, state, tempEntityState],
  );

  useEffect(() => {
    if (
      !!avatarState.newImage === false &&
      state.matches("entity.editing") &&
      state.context.error === undefined &&
      state.context?.data
    ) {
      dispatchAvatarAction({ type: "ResetToLatestSavedImage" });
    }
  }, [avatarState.newImage, dispatchAvatarAction, state]);

  // if we get into editing mode, we clone to our temp state
  useEffect(() => {
    const trans: Parameters<typeof actor.subscribe>[0] = state => {
      if (
        state.matches("entity.editing") &&
        state.context.error === undefined &&
        state.context?.data
      ) {
        setTempEntityState(cloneDeep(state.context?.data));
      }
    };

    const subscription = actor.subscribe(trans);

    return () => {
      subscription.unsubscribe();
    };
  }, [actor, setTempEntityState]);

  const _onSubmitAvatar = useCallback(async () => {
    if (avatarState.loading || avatarState.latestSavedImage === avatarState.newImage) {
      return;
    }

    if (avatarState.newImage === "delete") {
      await deleteAvatar();
      handlerCallbacks?.submitAvatar(undefined);
    } else if (avatarState.newImage) {
      await uploadAvatar();
      handlerCallbacks?.submitAvatar(getImageSrc(avatarState.newImage));
    }

    // ! CAREFUL HERE, This function is called many times due to state changes !
    // ! OUR Anchor points are based on newImage prop
    // ! DONT PUT handlerCallbacks outside of ifs
  }, [
    avatarState.latestSavedImage,
    avatarState.loading,
    avatarState.newImage,
    deleteAvatar,
    handlerCallbacks,
    uploadAvatar,
  ]);

  const onSubmit = useCallback(() => {
    void (async (e?: React.FormEvent) => {
      e?.preventDefault();

      if (saveDisabled) {
        return;
      }

      // We won't trigger upload if the data is same, just showing the toast
      // ! our current implementation of avatar saving on the backend is not ideal
      // ! we cannot get row version from them so we just ignore for the avatar
      send({ type: "SAVE_DATA", value: tempEntityState, saveAvatar: _onSubmitAvatar });
    })();
  }, [_onSubmitAvatar, saveDisabled, send, tempEntityState]);

  const onCancel = useCallback(() => {
    dispatchAvatarAction({ type: "ResetToLatestSavedImage" });
    send({ type: "CANCEL_EDIT" });
  }, [dispatchAvatarAction, send]);

  return { onSubmit, onCancel, saveDisabled };
}
