import { either, F, ifElse, is, isEmpty, isNil, map, pipe, reject, when } from "ramda";
import React, { PropsWithChildren } from "react";
import { assign, createMachine, fromPromise } from "xstate";
import { clearFilters, updateFiltersQueryURL, updateQueryURL } from "./helper";

interface TableProp {
  className?: string;
  theadSlot?: React.ReactNode;
  header?: React.ReactNode;
  paginator?: boolean;
  linesPerPageSelector?: boolean;
  viewTableSelector?: boolean;
  loading?: boolean;
  title?: string;
  noFilterData?: boolean;
  tableContainerAdditionalChildren?: React.ReactNode;
}
export type TableProps = PropsWithChildren<TableProp> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface BackendResponse {
  items: any[];
  [key: string | number]: any;
}

export type TableViewType = "List" | "Grid";
export type LinesPerPage = 10 | 20 | 50;

export interface TableContext<T extends BackendResponse, Filters extends Record<string, any>> {
  data: T;
  linesPerPage: LinesPerPage; // limit
  currentPage: number; // page
  totalResults: number; // query count
  totalPages: number;
  //#region local state
  search?: string;
  error: any;
  filters?: Filters;
  countFilters: number;
  tableView: TableViewType;
  //#endregion
}

export type SearchQuery<Filters extends Record<string, any>> = {
  tableView: TableViewType;
  linesPerPage: LinesPerPage;
  currentPage: number;
} & Partial<Filters>;

export type TableEvents<T, Filters extends Record<string, any>> =
  | { type: "UPDATE_TABLE_VIEW"; value: TableViewType }
  | { type: "UPDATE_LINES_PER_PAGE"; value: LinesPerPage }
  | { type: "UPDATE_CURRENT_PAGE"; value: number }
  | { type: "SEARCH"; value: string }
  | { type: "UPDATE_FILTERS"; value?: Filters }
  | { type: "NEXT_PAGE" }
  | { type: "PREVIOUS_PAGE" }
  | { type: "FETCH" }
  | { type: "RETRY" }
  | { type: "UPDATE_DATA"; processFunction: (currentData: T) => T }
  | { type: "UPDATE_QUERY"; value: SearchQuery<Filters> };

export type TableFetchFuncType<T extends BackendResponse, Filters extends Record<string, any>> = (
  context?: TableContext<T, Filters>,
) => Promise<T | undefined>;

const removeNulls: any = pipe(
  map(when(is(Object), (v: any) => removeNulls(v))),
  reject(ifElse(is(String), F, either(isEmpty, isNil))),
);

export function tableStateMachine<T extends BackendResponse, Filters extends Record<string, any>>(
  fetchFunc: TableFetchFuncType<T, Filters>,
  { currentPage, linesPerPage, tableView, ...filters }: SearchQuery<Filters> = {
    currentPage: 1,
    linesPerPage: 10,
    tableView: "List",
  } as SearchQuery<Filters>,
) {
  return createMachine(
    {
      context: {
        data: [] as any,
        linesPerPage: linesPerPage ?? 10, // limit
        currentPage: currentPage ?? 1,
        totalResults: 1,
        totalPages: 1,

        // internal state
        search: filters.contains,
        error: undefined,
        filters: filters as any,
        countFilters: Object.keys(filters).filter(key => key !== "contains").length ?? 0,
        tableView: tableView ?? "List",
      },
      types: {
        context: {} as TableContext<T, Filters>,
        events: {} as TableEvents<T, Filters>,
      },
      id: "table",
      initial: "init",
      states: {
        init: {
          on: {
            FETCH: {
              target: "loading",
            },
            UPDATE_FILTERS: {
              actions: "updateFilters",
              target: "loading",
            },
          },
        },
        loaded: {
          on: {
            UPDATE_TABLE_VIEW: {
              actions: "updateTableView",
              guard: ({ context, event }) => {
                return context.tableView !== event.value;
              },
            },
            FETCH: {
              actions: "fetch",
              target: "loading",
            },
            UPDATE_LINES_PER_PAGE: {
              actions: "updateLinesPerPage",
              guard: ({ context, event }) => {
                return context.linesPerPage !== event.value;
              },
              target: "loading",
            },
            UPDATE_CURRENT_PAGE: {
              actions: "updateCurrentPage",
              guard: ({ context, event }) => {
                return context.currentPage !== event.value;
              },
            },
            UPDATE_DATA: {
              actions: "updateData",
            },
            SEARCH: {
              actions: "updateSearch",
              target: "search",
            },
            UPDATE_FILTERS: {
              actions: "updateFilters",
              target: "loading",
            },
            NEXT_PAGE: [
              {
                actions: "nextPage",
                guard: ({ context }) => {
                  return context.currentPage < context.totalPages;
                },
                target: "loading",
              },
            ],
            PREVIOUS_PAGE: {
              actions: "previousPage",
              guard: ({ context }) => context.currentPage > 1,
              target: "loading",
            },
            UPDATE_QUERY: {
              actions: "updateQuery",
              target: "loading",
            },
          },
        },
        filtering: {
          initial: undefined,
        },
        search: {
          after: {
            "1000": {
              target: "loading",
            },
          },
          on: {
            SEARCH: {
              actions: "updateSearch",
              target: "search",
              reenter: true,
            },
          },
        },
        failure: {
          on: {
            RETRY: {
              target: "loading",
            },
          },
        },
        loading: {
          invoke: [
            {
              src: fromPromise(async ({ input }) => {
                return fetchFunc(input);
              }),
              input: ({ context }) => context,
              id: "getTableData",
              onDone: [
                {
                  actions: "processIncomingData",
                  target: "loaded",
                },
              ],
              onError: [
                {
                  actions: "failed",
                  target: "failure",
                },
              ],
            },
          ],
        },
      },
    },
    {
      actions: {
        failed: input => {
          console.error(`Failed to fetch data from function: ${fetchFunc.name}`, input.event);
        },
        fetch: assign({
          data: () => ({ items: [] as any }) as T,
          currentPage: () => {
            updateQueryURL("currentPage", 1);
            return 1;
          },
        }),

        updateTableView: assign({
          tableView: ({ event }) => {
            updateQueryURL("tableView", (event as any).value);
            return (event as any).value;
          },
        }),
        updateData: assign({
          data: ({ context, event }) => {
            return (event as any).processFunction(context.data);
          },
        }),
        updateLinesPerPage: assign({
          linesPerPage: ({ event }) => {
            updateQueryURL("linesPerPage", (event as any).value);
            return (event as any).value;
          },
          data: () => ({ items: [] as any }) as T,
          currentPage: () => {
            updateQueryURL("currentPage", 1);
            return 1;
          },
        }),
        updateCurrentPage: assign({
          currentPage: ({ event }) => {
            updateQueryURL("currentPage", (event as any).value);
            return (event as any).value;
          },
        }),
        updateSearch: assign({
          search: ({ event }) => (event as any).value,
        }),
        updateFilters: assign({
          filters: ({ context, event }) => {
            if ((event as any)?.value === undefined) {
              return undefined;
            }
            const result = removeNulls((event as any).value);

            if (isEmpty(result)) {
              clearFilters();
              return undefined;
            }
            const alreadyAppliedKeys = Object.keys(context.filters ?? {});
            const incomingKeys = Object.keys(result ?? {});
            const toBeDeleted = alreadyAppliedKeys.filter(el => !incomingKeys.includes(el));

            updateFiltersQueryURL(toBeDeleted, result);

            return result;
          },
          data: () => ({ items: [] as any }) as T,
          currentPage: () => {
            updateQueryURL("currentPage", 1);
            return 1;
          },
          countFilters: ({ event }) => {
            if ((event as any).value === undefined) {
              return 0;
            }
            const result = removeNulls((event as any).value);
            if (isEmpty(result)) return 0;
            const resultCount = Object.keys(result).length;

            return Object.hasOwn(result, "contains") ? resultCount - 1 : resultCount;
          },
        }),
        updateQuery: assign({
          currentPage: ({ context, event }) =>
            (event as any).value.currentPage ?? context.currentPage,
          linesPerPage: ({ context, event }) =>
            (event as any).value.linesPerPage ?? context.linesPerPage,
          tableView: ({ context, event }) => (event as any).value.tableView ?? context.tableView,
          filters: ({ context, event }) => {
            if ((event as any)?.value === undefined) {
              return undefined;
            }
            const result = removeNulls((event as any).value);

            if (isEmpty(result)) {
              clearFilters();
              return undefined;
            }
            const alreadyAppliedKeys = Object.keys(context.filters ?? {});
            const incomingKeys = Object.keys(result ?? {});
            const toBeDeleted = alreadyAppliedKeys.filter(el => !incomingKeys.includes(el));

            updateFiltersQueryURL(toBeDeleted, result);

            return result;
          },
          countFilters: ({ event }) => {
            if ((event as any).value === undefined) {
              return 0;
            }

            const result = removeNulls((event as any).value);

            if (isEmpty(result)) {
              return 0;
            }

            const resultCount = Object.keys(result).filter(
              // we want to ignnore table related state
              el => el !== "currentPage" && el !== "linesPerPage" && el !== "tableView",
            ).length;

            return Object.hasOwn(result, "contains") ? resultCount - 1 : resultCount;
          },
        }),
        nextPage: assign({
          currentPage: ({ context }) => {
            updateQueryURL("currentPage", context.currentPage + 1);
            return context.currentPage + 1;
          },
        }),
        previousPage: assign({
          currentPage: ({ context }) => {
            updateQueryURL("currentPage", context.currentPage - 1);
            return context.currentPage - 1;
          },
        }),
        processIncomingData: assign({
          data: ({ context, event }) => {
            const beResponse = (event as any).output;

            if (beResponse && !beResponse?.items) {
              return { ...beResponse, items: [] };
            } else if (beResponse && beResponse.items) {
              return beResponse;
            }

            return context.data;
          },
          totalResults: ({ context, event }) => {
            return (event as any).output?.queryCount ?? context.totalResults;
          },
          totalPages: ({ context, event }) => {
            return (event as any).output?.pages ?? context.totalPages;
          },
        }),
      },
    },
  );
}
