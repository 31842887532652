import { useCallback } from "react";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { RedirectProps, ROUTES } from "router";
import { BackendResponse, LinesPerPage, SearchQuery, TableContext, TableViewType } from "./model";

type TableElementsRedirectProps<PATH extends keyof typeof ROUTES> = RedirectProps<PATH> & {
  event?: React.MouseEvent<HTMLElement, MouseEvent>;
};

const TABLE_QUERY_PARAMS_INIT: SearchQuery<Record<string, any>> = {
  tableView: "List",
  linesPerPage: 10,
  currentPage: 1,
};

function mapToView(tableView: string | null) {
  return tableView &&
    (["Grid", "List"] satisfies TableViewType[]).includes(tableView as TableViewType)
    ? (tableView as TableViewType)
    : "List";
}

function mapToLinesPerPage(linesPerPage: string | null) {
  return linesPerPage && [10, 20, 50].includes(Number(linesPerPage))
    ? (Number(linesPerPage) as LinesPerPage)
    : 10;
}

function mapToPage(page: string | null) {
  return page && Number.isInteger(Number(page)) ? Number(page) : 1;
}

const singleValueArrayKeys = [
  "userStatuses",
  "userRoles",
  "organizationIds",
  "countries",
  "states",
  "deviceStatuses",
  "hcpIds",
];

function parseDynamicFilter(value: any, key: string) {
  const temp = value.split(",");

  if (temp.length === 1) {
    const singleValue = temp[0];

    if (["true", "false"].includes(singleValue)) {
      return Boolean(singleValue);
    }

    return singleValueArrayKeys.includes(key) ? [singleValue] : singleValue;
  }

  return temp;
}

export function mapTableQueryParamsFromURL<T extends Record<string, any>>(
  queryParams: URLSearchParams,
): SearchQuery<T> {
  const test = {} as any;
  queryParams.forEach((value, key) => {
    switch (key) {
      case "tableView":
        test[key] = mapToView(value);
        break;
      case "linesPerPage":
        test[key] = mapToLinesPerPage(value);
        break;
      case "currentPage":
        test[key] = mapToPage(value);
        break;

      default:
        test[key] = parseDynamicFilter(value, key);
        break;
    }
  });

  return test;
}

export function useTableURLParams<Filters extends Record<string, any>>() {
  const [searchParams, setSearchParams] = useSearchParams(TABLE_QUERY_PARAMS_INIT);

  const setParams = useCallback(
    (queryParams?: SearchQuery<Filters>) => {
      const params = new URLSearchParams();
      const { tableView, linesPerPage, currentPage } = queryParams ?? {};

      if (tableView) {
        params.append("tableView", tableView);
      }

      if (linesPerPage) {
        params.append("linesPerPage", linesPerPage.toString());
      }

      if (currentPage) {
        params.append("currentPage", currentPage.toString());
      }

      setSearchParams(params.toString());
    },
    [setSearchParams],
  );

  return [searchParams, setParams] as const;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TableUtils {
  export function sliceRowsByPage<T>(data: T[], currentPage: number, linesPerPage: number) {
    return data.slice(
      (currentPage - 1) * linesPerPage,
      (currentPage - 1) * linesPerPage + linesPerPage,
    );
  }

  export function resolveValue(value: any) {
    return typeof value === "function" ? value() : value;
  }

  export function useTableElementsRedirect() {
    const navigate = useNavigate();

    return useCallback(
      <T extends keyof typeof ROUTES>({
        event,
        path,
        params,
        options,
      }: TableElementsRedirectProps<T>) => {
        event?.preventDefault();
        event?.stopPropagation();

        // we need string here coz of -> https://reactrouter.com/en/main/utils/generate-path
        navigate(generatePath(ROUTES[path] as string, params), options);
      },
      [navigate],
    );
  }

  export function areFiltersApplied<T extends BackendResponse, B extends Record<string, any>>(
    context: TableContext<T, B>,
  ) {
    return !!context.filters && !!Object.entries(context.filters).length;
  }
}
