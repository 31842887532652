import { querystring } from "./endpointSerialization";
import { HTTP_FORBIDDEN, HTTP_UNAUTHORIZED } from "./const";
import {
  AdministratorsApi,
  AuthApi,
  Configuration,
  ProfileApi,
  PartnersApi,
  UserPreferencesApi,
  UsersApi,
  OrganizationsApi,
  PatientsApi,
  HcpsApi,
  DevicesApi,
  TestsApi,
  EventsApi,
  SupportApi,
  TrackingApi,
  AccountDataApi,
  GraphsApi,
  MeasurementsApi,
  GeneralPractitionersApi,
  PatientResponseModel,
} from "api/query";
import { injectUnauthorized } from "components/Unathorized";
import { fetchAuthSession } from "@aws-amplify/auth";

let checkingForForbiddenUser: Promise<any> | null = null;

export const configurationAuthApi = new Configuration({
  basePath: `${process.env.VITE_APP_QUERY_HOST ?? "VITE_APP_QUERY_HOST-env-variable-missing"}`,
});

export const configuration =
  process.env.MODE === "test"
    ? undefined
    : new Configuration({
        /**
         * check this function in runtime.ts
         */
        queryParamsStringify: querystring,
        basePath: `${
          process.env.VITE_APP_QUERY_HOST ?? "VITE_APP_QUERY_HOST-env-variable-missing"
        }`,
        // (input: RequestInfo | URL, init?: RequestInit): Promise<Response>
        middleware: [
          {
            async post(param) {
              if (param.response.status === HTTP_UNAUTHORIZED) {
                injectUnauthorized();
                return param.response;
              }

              if (param.response.status === HTTP_FORBIDDEN) {
                // Avoiding concurrency
                if (checkingForForbiddenUser) {
                  await checkingForForbiddenUser;
                } else {
                  try {
                    checkingForForbiddenUser = fetchAuthSession({ forceRefresh: true });
                    await checkingForForbiddenUser;
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  } catch (error) {
                    console.warn("User is possibly logged out");
                  } finally {
                    checkingForForbiddenUser = null;
                  }
                }
              }

              return param.response;
            },
            async pre(context) {
              return {
                url: context.url,
                init: {
                  ...context.init,
                  headers: new Headers({
                    ...context.init.headers,
                    // ...sign.headers,
                    "access-token": `${(await fetchAuthSession()).tokens?.accessToken}`,
                    "identity-token": `${(await fetchAuthSession()).tokens?.idToken}`,
                  }),
                },
              };
            },
          },
        ],
      });

const AdminApiContext = new AdministratorsApi(configuration);
const PartnerApiContext = new PartnersApi(configuration);
const UsersApiContext = new UsersApi(configuration);
const OrganizationApiContext = new OrganizationsApi(configuration);
const PatientApiContext = new PatientsApi(configuration);
const HcpsApiContext = new HcpsApi(configuration);
const DevicesApiContext = new DevicesApi(configuration);
const TestsApiContext = new TestsApi(configuration);
const ProfileApiContext = new ProfileApi(configuration);

const TrackingDevicesApiContext = new TrackingApi(configuration);
const AccountDataApiContext = new AccountDataApi(configuration);

const UserPreferenceApiContext = new UserPreferencesApi(configuration);
const EventsApiContext = new EventsApi(configuration);
const SupportApiContext = new SupportApi(configuration);
const GraphsApiContext = new GraphsApi(configuration);
const MeasurementApiContext = new MeasurementsApi(configuration);
const GeneralPractitionersApiContext = new GeneralPractitionersApi(configuration);

function convertStringsToArrays(obj: any) {
  const newObj = {};

  function convert(obj: any, result: any = {}, keys = Object.keys(obj), index = 0) {
    if (index === keys.length) {
      return result;
    }

    const key = keys[index];
    const value = obj[key];

    if (typeof value === "string" && value.includes(",")) {
      result[key] = value.split(",");
    } else {
      result[key] = value;
    }

    return convert(obj, result, keys, index + 1);
  }

  return convert(obj, newObj);
}

export const CarnaApiQuery = {
  Admin: {
    getDevices: AdministratorsApi.prototype.devicesGet.bind(AdminApiContext),
    getHcps: AdministratorsApi.prototype.hcpsGet.bind(AdminApiContext),
    getPatients: AdministratorsApi.prototype.patientsGet.bind(AdminApiContext),
    getByOrganization:
      AdministratorsApi.prototype.organizationsOrganizationIdAdministratorsGet.bind(
        AdminApiContext,
      ),
    getMeasurements: MeasurementsApi.prototype.measurementsGet.bind(MeasurementApiContext),
  } as const,
  Partners: {
    getMeasurements:
      MeasurementsApi.prototype.organizationsOrganizationIdMeasurementsGet.bind(
        MeasurementApiContext,
      ),
    get: PartnersApi.prototype.organizationsOrganizationIdPartnersGet.bind(PartnerApiContext),
    getById: PartnersApi.prototype.organizationsOrganizationIdPartnersIdGet.bind(PartnerApiContext),
  } as const,
  Users: {
    get: UsersApi.prototype.usersGet.bind(UsersApiContext),
    getById: UsersApi.prototype.usersIdGet.bind(UsersApiContext),
  } as const,
  Organizations: {
    get: OrganizationsApi.prototype.organizationsGet.bind(OrganizationApiContext),
    getById: OrganizationsApi.prototype.organizationsOrganizationIdGet.bind(OrganizationApiContext),
    getMeasurements:
      MeasurementsApi.prototype.organizationsOrganizationIdMeasurementsGet.bind(
        MeasurementApiContext,
      ),
  } as const,

  Patients: {
    get: PatientsApi.prototype.organizationsOrganizationIdPatientsGet.bind(PatientApiContext),
    getById: async (
      ...params: Parameters<
        typeof PatientsApi.prototype.organizationsOrganizationIdPatientsPatientEntityIdGet
      >
    ) => {
      let result: PatientResponseModel;

      // eslint-disable-next-line no-useless-catch
      try {
        result =
          await PatientsApi.prototype.organizationsOrganizationIdPatientsPatientEntityIdGet.bind(
            PatientApiContext,
          )(...params);
      } catch (error) {
        throw error;
      }

      result.additionalProperties = convertStringsToArrays(result?.additionalProperties ?? {});
      return result;
    },
    // PatientsApi.prototype.organizationsOrganizationIdPatientsPatientEntityIdGet.bind(
    //   PatientApiContext,
    // ),
    getMeasurements:
      MeasurementsApi.prototype.organizationsOrganizationIdPatientsUserEntityIdMeasurementsGet.bind(
        MeasurementApiContext,
      ),
    getWidgets:
      PatientsApi.prototype.organizationsOrganizationIdPatientsPatientEntityIdWidgetsGet.bind(
        PatientApiContext,
      ),
    getWidget:
      PatientsApi.prototype.organizationsOrganizationIdPatientsPatientEntityIdWidgetsWidgetIdGet.bind(
        PatientApiContext,
      ),
  } as const,
  Hcps: {
    get: HcpsApi.prototype.organizationsOrganizationIdHcpsGet.bind(HcpsApiContext),
    getById: HcpsApi.prototype.organizationsOrganizationIdHcpsHcpEntityIdGet.bind(HcpsApiContext),
    getMeasurements:
      MeasurementsApi.prototype.organizationsOrganizationIdMeasurementsGet.bind(
        MeasurementApiContext,
      ),
    getPatients:
      HcpsApi.prototype.organizationsOrganizationIdHcpsHcpEntityIdPatientsGet.bind(HcpsApiContext),
    getPatientsMeasurements:
      MeasurementsApi.prototype.organizationsOrganizationIdHcpsUserEntityIdPatientsMeasurementsGet.bind(
        MeasurementApiContext,
      ),
  } as const,
  GeneralPractitioners: {
    get: GeneralPractitionersApi.prototype.generalPractitionersGet.bind(
      GeneralPractitionersApiContext,
    ),
  } as const,
  Devices: {
    get: DevicesApi.prototype.organizationsOrganizationIdDevicesGet.bind(DevicesApiContext),
    getById:
      DevicesApi.prototype.organizationsOrganizationIdDevicesDeviceEntityIdGet.bind(
        DevicesApiContext,
      ),
    getByUserId:
      DevicesApi.prototype.organizationsOrganizationIdDevicesUserUserEntityIdGet.bind(
        DevicesApiContext,
      ),
    getMeasurements:
      MeasurementsApi.prototype.organizationsOrganizationIdDevicesUserEntityIdMeasurementsGet.bind(
        MeasurementApiContext,
      ),
  } as const,
  Tests: {
    get: TestsApi.prototype.testsGet.bind(TestsApiContext),
    getByOrganizationId:
      TestsApi.prototype.organizationsOrganizationIdTestsGet.bind(TestsApiContext),
    getByPatientId:
      TestsApi.prototype.organizationsOrganizationIdPatientsUserEntityIdTestsGet.bind(
        TestsApiContext,
      ),
    getByHcpId:
      TestsApi.prototype.organizationsOrganizationIdHcpsUserEntityIdPatientsTestsGet.bind(
        TestsApiContext,
      ),
    getByDeviceId:
      TestsApi.prototype.organizationsOrganizationIdDevicesUserEntityIdTestsGet.bind(
        TestsApiContext,
      ),
  } as const,
  Test: {
    getByOrganizationId:
      TestsApi.prototype.organizationsOrganizationIdTestsIdGet.bind(TestsApiContext),
    getByOrganizationPatientId:
      TestsApi.prototype.organizationsOrganizationIdPatientsUserEntityIdTestsTestEntityIdGet.bind(
        TestsApiContext,
      ),
  } as const,

  ProfileApi: {
    getProfile: ProfileApi.prototype.profileGet.bind(ProfileApiContext),
  } as const,

  UserPreferences: {
    organizationsOrganizationIdUsersIdPreferencesGet:
      UserPreferencesApi.prototype.organizationsOrganizationIdUsersIdPreferencesGet.bind(
        UserPreferenceApiContext,
      ),
  } as const,

  Auth: new AuthApi(configurationAuthApi),

  Event: {
    get: async (id: string) => EventsApi.prototype.eventsIdGet.bind(EventsApiContext)({ id }),
  } as const,

  Support: {
    post: SupportApi.prototype.supportFormsPost.bind(SupportApiContext),
  } as const,

  SignedInDeviceHistory: {
    get: TrackingApi.prototype.trackingDevicesGet.bind(TrackingDevicesApiContext),
  } as const,

  AccountData: {
    post: AccountDataApi.prototype.organizationsOrganizationIdUsersUserEntityIdAccountDataPost.bind(
      AccountDataApiContext,
    ),
  } as const,

  GraphsApi: {
    get: GraphsApi.prototype.organizationsOrganizationIdPatientsUserEntityIdMeasurementsGraphsGet.bind(
      GraphsApiContext,
    ),
  } as const,
} as const;

declare global {
  interface Window {
    ApiQuery: any;
  }
}

if (import.meta.env.MODE === "development") {
  window.ApiQuery = CarnaApiQuery;
}
