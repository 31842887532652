import { faker } from "@faker-js/faker";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { AvatarType } from "libs/ui/Avatar";
import { Paginator } from "libs/ui/Paginator/Paginator";
import { ReportList } from "libs/ui/ReportList";
import { TimestampTooltip } from "libs/ui/ReportList/TimestampTooltip";
import RowsPerPage from "libs/ui/RowsPerPage";
import { random } from "lodash-es";
import { failed, loaded, loading } from "models/loadable";
import { useSyncExternalStore } from "react";
import { PictureResolution } from "utils/helpers/pictures.model";
import { avatarCache } from "utils/machines/avatars";
import "./AllReports.scss";

function getReportListProps(firstName: string, avatarType: AvatarType, avatarSrc?: string) {
  return Array.from({ length: random(5, 10) }, () => {
    return {
      title: "Report 1",
      description: "Description 1",
      report: faker.helpers.arrayElement([loading(null), loaded(null), failed(null)]),
      timestamps: [
        {
          tooltip: <TimestampTooltip username={firstName} avatarSrc={avatarSrc} />,
          type: "Requested",
          date: new Date(),
        },
        {
          tooltip: <TimestampTooltip username={firstName} avatarSrc={avatarSrc} />,
          type: "Created",
          date: new Date(),
        },
        {
          type: "Download",
          date: new Date(),
        },
      ],
    } as const;
  });
}

export function AllReports() {
  const { firstName, lastName, organizationId, userRole, currentUserId } =
    useAdditionalAuthInformationContext();

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  return (
    <div className="AllReports">
      <ReportList
        reports={getReportListProps(
          `${firstName} ${lastName}`,
          userRole,
          avatarCache.get(organizationId, currentUserId, PictureResolution["112x112"]),
        )}
      />
      <div className="AllReports--footer">
        <RowsPerPage
          setRowsPerPage={function (rows: number): void {
            throw new Error("Function not implemented.");
          }}
          tableView={"List"}
        />
        <Paginator
          currentPage={1}
          queryCount={100}
          limit={10}
          gotoPage={function (page: number): void {
            throw new Error("Function not implemented.");
          }}
        />
      </div>
    </div>
  );
}
