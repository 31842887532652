import { DeviceResponseModel, DeviceType } from "api/query";
import { resolveRenderCell, resolveUserStatus } from "components/AppTables/utils";
import { DeviceImage, TableElements } from "libs/ui";
import { TableUtils } from "libs/ui/Table/utils";
import { useSyncExternalStore } from "react";
import { useTranslation } from "react-i18next";
import { FakeData, isRealData } from "utils/guards";
import { PictureResolution } from "utils/helpers/pictures.model";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { avatarCache } from "utils/machines/avatars";
import { deviceTypeToSelectOptions } from "utils/mappers/deviceTypeToSelectOptions";
import { TableCard } from "../../TableCard";
import { TableCardDescription } from "../../TableCardDescription";
import { TableCardPersonAvatar } from "../../TableCardPersonAvatar";
import { BodyRowsProps } from "../model";

export function BodyRows({ tableService, appliedColumns }: Readonly<BodyRowsProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "DevicesTable" });

  const can = useHasAccess();
  const tableRedirection = TableUtils.useTableElementsRedirect();
  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  const [state] = tableService;

  const defaultValues = Array.from({ length: state.context.linesPerPage }).map((_, idx) => {
    const defaultValue: FakeData<DeviceResponseModel> = {
      _fake: true,
      status: "Inactive",
      deviceType: "NovaMaxProCreateEgfrMeter",
      manufacturerType: "NovaBiomedical",
      organizationId: "",
      organizationName: "",
      id: idx.toString(),
      serialNumber: "",
      uniqueId: "",
      creationDate: new Date(),
      modifyDate: new Date(),
      rowVersion: "",
    };
    return defaultValue;
  });

  const getDeviceTypeName = (deviceType: DeviceType) => {
    const deviceName = deviceTypeToSelectOptions().find(el => el.value === deviceType)?.title;

    if (deviceName === undefined) {
      throw new Error(`Device name not found`);
    }

    return deviceName;
  };

  return (
    <>
      {(state.matches("loading") ? defaultValues : state.context.data.items ?? []).map(device => {
        if (state.context.tableView === "Grid") {
          return (
            <TableCard
              key={device.id}
              loading={state.matches("loading")}
              avatarData={<DeviceImage loading={state.matches("loading")} />}
              name={getDeviceTypeName(device.deviceType)}
              organization={{ id: device.organizationId, name: device.organizationName }}
              description={
                <TableCardDescription
                  title={device.serialNumber}
                  textLength="truncate"
                  textType="light"
                  loading={state.matches("loading")}
                >
                  {device.serialNumber}
                </TableCardDescription>
              }
              onClick={() =>
                tableRedirection({
                  path: "deviceDetails",
                  params: {
                    deviceId: device.id,
                    organizationId: device.organizationId,
                  },
                })
              }
            >
              <TableCardPersonAvatar
                show={can("manage-patient") && resolveRenderCell(appliedColumns, "userIds")}
                userData={{
                  id: device.assignedTo?.id,
                  loading: false,
                  src: isRealData(device)
                    ? avatarCache.get(
                        device.assignedTo?.organizationId,
                        device.assignedTo?.id,
                        PictureResolution.grid,
                        true,
                      )
                    : undefined,
                  // mrn: registryNumber,
                  organizationId: device.organizationId,
                  name: `${device.assignedTo?.firstName} ${device.assignedTo?.lastName}`,
                  role: device.assignedTo?.role,
                }}
                loading={state.matches("loading")}
              >
                <TableCardDescription>{t("na")}</TableCardDescription>
              </TableCardPersonAvatar>
            </TableCard>
          );
        }

        return (
          <TableElements.Row
            key={device.id}
            inactive={device.status === "Inactive"}
            {...(can("manage-device") &&
              !state.matches("loading") && {
                onClick: () => {
                  tableRedirection({
                    path: "deviceDetails",
                    params: {
                      deviceId: device.id,
                      organizationId: device.organizationId,
                    },
                  });
                },
              })}
          >
            <TableElements.DeviceCell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "deviceType")}
              deviceSerialNumber={device.serialNumber}
              deviceModel={getDeviceTypeName(device.deviceType)}
            />
            <TableElements.Cell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "manufacturerType")}
            >
              {device.manufacturerType}
            </TableElements.Cell>
            <TableElements.Cell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "serialNumber")}
            >
              {device.serialNumber}
            </TableElements.Cell>
            <TableElements.UserOrganizationCell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "organizationIds")}
              {...(can("manage-organization") && {
                onCellClick: e =>
                  tableRedirection({
                    event: e,
                    path: "organizationDetails",
                    params: { organizationId: device.organizationId, firstTab: "partners" },
                  }),
              })}
              role="Partner"
              label={device.organizationName}
            />
            <TableElements.Status
              show={resolveRenderCell(appliedColumns, "deviceStatuses")}
              status={resolveUserStatus(device.status)}
              label={device.status}
              loading={state.matches("loading")}
            />

            <TableElements.AvatarCell
              loading={state.matches("loading")}
              cellProps={{ "data-testid": "assign-to" }}
              show={can("manage-patient") && resolveRenderCell(appliedColumns, "userIds")}
              src={
                isRealData(device)
                  ? avatarCache.get(
                      device.assignedTo?.organizationId,
                      device.assignedTo?.id,
                      PictureResolution.table,
                      true,
                    )
                  : undefined
              }
              avatarLoading={false}
              firstName={device.assignedTo?.firstName}
              label={
                device.assignedTo?.firstName
                  ? `${device.assignedTo?.firstName} ${device.assignedTo?.lastName}`
                  : undefined
              }
              avatarType={device.assignedTo?.role === "Patient" ? "Patient" : "Hcp"}
              {...(!!device.id && {
                onCellClick: e =>
                  tableRedirection({
                    event: e,
                    path: device.assignedTo?.role === "Patient" ? "patientDetails" : "hcpDetails",
                    params: {
                      organizationId: device.organizationId,
                      ...(device.assignedTo?.role === "Patient"
                        ? { patientId: device.assignedTo?.id }
                        : { hcpId: device.assignedTo?.id }),
                    } as any,
                  }),
              })}
            >
              {t("na")}
            </TableElements.AvatarCell>
          </TableElements.Row>
        );
      })}
    </>
  );
}
