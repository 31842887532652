import { EditableDetails } from "components/EditableDetails/EditableDetails";
import { useAppMenuContext } from "context/AppMenuContext";
import { TFunction } from "i18next";
import { UserRoleType, UserStatusType } from "models/PersonModels";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { getImageSrc } from "utils/converters/getImageSrc";
import { useFormHandlers } from "utils/helpers/pages/details";
import { useAvatarState } from "utils/hooks/useAvatarState";

import { useMachine } from "@xstate/react";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { cloneDeep } from "lodash-es";
import { makeDetailsPageStateMachine } from "utils/machines/pages/details/makeDetailsPageStateMachine";
import AvatarGroup from "./AvatarGroup";
import { onSaveAvatar } from "./helpers";

interface PersonModel {
  status: UserStatusType;
  firstName: string;
  lastName: string;
  role?: UserRoleType;
}

interface AvatarParams {
  organizationId: string;
  personId: string;
  latestRowVersion: string;
}

type AnyDetailsServiceModel<T extends PersonModel> = ReturnType<
  typeof useMachine<ReturnType<typeof makeDetailsPageStateMachine<T, any>>>
>;

interface Props<PModel extends PersonModel> {
  detailsService: AnyDetailsServiceModel<PModel>;
  avatarParams: AvatarParams;
  canEdit?: boolean;
  getFieldsReady: (tempEntityState: PModel) => boolean;
  defaultFormValue: PModel;
  FormElem: React.ElementType;
  t: TFunction<string, string>;
  onClose?: () => void;
}

export function DetailsCommonPersonForm<PModel extends PersonModel>({
  detailsService,
  canEdit = true,
  avatarParams: { organizationId, personId, latestRowVersion },
  getFieldsReady,
  defaultFormValue,
  t,
  FormElem,
  onClose,
}: PropsWithChildren<Props<PModel>>) {
  const { dispatchSideAvatarAction } = useAppMenuContext();
  const { currentUserId } = useAdditionalAuthInformationContext();
  const [state] = detailsService;
  const [localEntityData, setLocalEntityData] = useState<PModel>(() =>
    state.matches("entity.editing") && state.context.error === undefined && state.context?.data
      ? cloneDeep(state.context.data)
      : {
          ...defaultFormValue,
        },
  );

  const inSavingOrLoading = state.matches("entity.saving") || state.matches("entity.loading");

  //#region AVATAR
  const { avatarState, dispatchAvatarAction, loadAvatar, deleteAvatar, uploadAvatar } =
    useAvatarState();

  const presentedAvatarImg = useMemo(() => {
    if (avatarState.newImage === "delete") {
      return undefined;
    }

    return getImageSrc(avatarState.newImage) ?? avatarState.latestSavedImage ?? undefined;
  }, [avatarState.latestSavedImage, avatarState.newImage]);

  useEffect(() => {
    if (avatarState._init) {
      loadAvatar(organizationId, personId);
    }
  }, [avatarState._init, loadAvatar, organizationId, personId]);

  //#endregion

  const handlerCbs = useMemo(
    () => ({
      submitAvatar(newImage: string | undefined) {
        /**
         * Our Navigation menu Avatar picture must be refreshed
         * ! only if the current user is the one who is editing his profile picture
         */
        if (currentUserId === personId) {
          dispatchSideAvatarAction({
            type: "SetLatestSavedImage",
            payload: { latestSavedImage: newImage },
          });
        }
      },
    }),
    [currentUserId, dispatchSideAvatarAction, personId],
  );

  const { onSubmit, onCancel, saveDisabled } = useFormHandlers(
    detailsService,
    {
      avatarState,
      dispatchAvatarAction,
      async uploadAvatar() {
        return uploadAvatar(organizationId, personId);
      },
      async deleteAvatar() {
        return deleteAvatar(organizationId, personId, latestRowVersion);
      },
    },
    [localEntityData, setLocalEntityData],
    getFieldsReady,
    handlerCbs,
  );

  const allowedEdit = canEdit
    ? state.matches("entity.editing") || state.matches("entity.saving")
    : undefined;

  return (
    <EditableDetails
      title={
        state.matches("entity.editing") || state.matches("entity.saving")
          ? t("titleEdit", {
              role: t(`role.${state.context.data?.role}`),
            })
          : t("title")
      }
      {...(allowedEdit && {
        editMode: true,
      })}
      isLoaded={state.matches("entity.loaded")}
      cancel={{
        disabled: inSavingOrLoading,
        onClick: onCancel,
      }}
      save={{
        disabled: saveDisabled,
        onClick: onSubmit,
        isLoading: state.matches("entity.saving"),
      }}
      onClose={state.matches("entity.editing") ? onClose : undefined}
    >
      <FormElem
        onSubmit={onSubmit}
        service={detailsService}
        entityData={localEntityData}
        setEntityData={setLocalEntityData}
        loading={state.matches("entity.loading")}
      >
        <AvatarGroup
          avatarForCreate={state.matches("entity.editing") && !!presentedAvatarImg === false}
          loading={state.matches("entity.loading") || avatarState.loading}
          editing={state.matches("entity.editing")}
          firstName={state.context.data?.firstName ?? ""}
          imageSrc={presentedAvatarImg}
          onSave={onSaveAvatar(avatarState, dispatchAvatarAction)}
        />
      </FormElem>
    </EditableDetails>
  );
}
