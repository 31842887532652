import "./Login.scss";

import { confirmResetPassword, resetPassword, signIn, SignInInput } from "@aws-amplify/auth";
import { Amplify } from "aws-amplify";
import { I18n as AmplifyI18n } from "aws-amplify/utils";
import { PropsWithChildren, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "utils/hooks/useLocalStorage";

import { Authenticator, AuthenticatorProps, useAuthenticator } from "@aws-amplify/ui-react";

import { Copyright } from "components/Copyright";
import { CarnaApiQuery } from "config/apiQuery";
import { globalConfigStore } from "config/globalConfig";
import { toastStore } from "config/toast";
import i18n from "i18next";
import ConfirmResetPassword from "layout/Login/ConfirmResetPassword/index";
import LoginPagesHeader from "layout/Login/LoginPagesHeader";
import { Logo } from "libs/ui";
import { FetchingErrorPage } from "pages/ErrorBoundary/FetchingErrorPage";
import SuccessfulPasswordChange from "pages/SuccessfulPasswordChange";
import { useMemo } from "react";
import isEmail from "validator/lib/isEmail";
import AuthenticatorWrapper from "./AuthenticatorWrapper";
import { ForceNewPasswordFormFields } from "./ForceNewPasswordFormFields";

export const Login = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation("translation", { keyPrefix: "Login" });
  const { t: tErrorPages } = useTranslation("translation", {
    keyPrefix: "ErrorPages.NoNetworkErrorPage",
  });
  const { setLocalStorageItem } = useLocalStorage("lastUsedAuth");
  const { route, toSignIn } = useAuthenticator(context => [context.route, context.toSignIn]);

  const [displaySuccessfulPasswordChange, setDisplaySuccessfulPasswordChange] = useState(false);
  const [displayNetworkError, setDisplayNetworkError] = useState(false);

  const [forgotPasswordUsername, setForgotPasswordUsername] = useState("");
  const [confirmResetPasswordErrorCode, setConfirmResetPasswordErrorCode] = useState("");

  AmplifyI18n.putVocabulariesForLanguage("en", {
    "Send code": "Submit",
    "challengeResponse is required to confirmSignIn": "Password cannot be empty",
  });

  const formFields: AuthenticatorProps["formFields"] = useMemo(
    () => ({
      signIn: {
        username: {
          labelHidden: true,
          placeholder: t("SignIn.email"),
        },
        password: {
          labelHidden: true,
          placeholder: t("SignIn.password"),
        },
      },
      confirmSignIn: {
        confirmation_code: {
          labelHidden: true,
          placeholder: "Enter your Confirmation Code:",
        },
      },
      forgotPassword: {
        username: {
          labelHidden: true,
          placeholder: t("ResetPassword.email"),
        },
      },
      forceNewPassword: {
        password: {
          labelHidden: true,
          placeholder: t("ForceNewPassword.newPassword"),
        },
        confirm_password: {
          labelHidden: true,
          placeholder: t("ForceNewPassword.confirmNewPassword"),
        },
      },
    }),
    [t],
  );

  const components: AuthenticatorProps["components"] = useMemo(
    () => ({
      SignIn: {
        Header() {
          return <LoginPagesHeader title={t("SignIn.title")} text={[t("SignIn.text")]} />;
        },
      },
      SetupTotp: {
        Header() {
          return (
            <LoginPagesHeader
              title={t("SetupTOTP.title")}
              text={[
                t("SignIn.text"),
                t("SetupTOTP.title"),
                t("SetupTOTP.text1"),
                t("SetupTOTP.text2"),
                t("SetupTOTP.text3"),
              ]}
            />
          );
        },
      },
      ConfirmSignIn: {
        Header() {
          return (
            <LoginPagesHeader title={t("ConfirmSignIn.title")} text={[t("ConfirmSignIn.text")]} />
          );
        },
      },
      ForgotPassword: {
        Header() {
          return (
            <LoginPagesHeader title={t("ResetPassword.title")} text={[t("ResetPassword.text")]} />
          );
        },
      },

      ConfirmResetPassword: {
        Header() {
          return (
            <LoginPagesHeader
              title={t("ConfirmResetPassword.title")}
              text={[t("ConfirmResetPassword.text")]}
            />
          );
        },
      },
      ForceNewPassword: {
        FormFields() {
          return (
            <ForceNewPasswordFormFields
              title={t("ForceNewPassword.title")}
              text={[t("ForceNewPassword.text")]}
            />
          );
        },
      },
    }),
    [t],
  );

  const forgotPasswordSubmit = useCallback(
    (formData: { code: string; password: string }) => {
      const { code, password } = formData;
      setConfirmResetPasswordErrorCode("");

      confirmResetPassword({
        confirmationCode: code,
        newPassword: password,
        username: forgotPasswordUsername,
      })
        .then(() => {
          setDisplaySuccessfulPasswordChange(true);
        })
        .catch(err => {
          if (err.code === "NetworkError") {
            setDisplayNetworkError(true);
          } else {
            setConfirmResetPasswordErrorCode(err.name);
          }
          console.error(err);
        });
    },
    [forgotPasswordUsername],
  );

  const setupAuthObj = useCallback(
    async (userName: string) => {
      const amplifyConfig = await CarnaApiQuery.Auth.authGet(
        { userName },
        {
          headers: new Headers({
            "access-token": "fake",
            "identity-token": "fake",
          }),
        },
      );

      const authObj = {
        identityPoolId: amplifyConfig.identityPoolId,
        userPoolId: amplifyConfig.userPoolId,
        userPoolClientId: amplifyConfig.clientId,
      } as const;

      Amplify.configure({
        Auth: {
          Cognito: authObj,
        },
      });

      setLocalStorageItem(authObj);

      toastStore.clear();
      globalConfigStore?.clear();
      setForgotPasswordUsername(userName);
    },
    [setLocalStorageItem],
  );

  const services: AuthenticatorProps["services"] = useMemo(
    () => ({
      async handleForgotPassword(input) {
        if (!isEmail(input.username)) {
          return Promise.reject(
            i18n.t("Amplify.ForgotPassword.wrong_email", { ns: "translation" }),
          );
        }

        await setupAuthObj(input.username);

        setForgotPasswordUsername(input.username);
        return resetPassword(input);
      },
      async handleSignIn(input: SignInInput) {
        const username = input.username.trim();

        if (!isEmail(username)) {
          throw new Error(i18n.t("Amplify.SignIn.wrong_email", { ns: "translation" }));
        }

        if (input.password === "") {
          throw new Error(i18n.t("Amplify.SignIn.empty_password", { ns: "translation" }));
        }

        await setupAuthObj(username);

        return signIn(input)
          .then(user => {
            return user;
          })
          .catch(error => {
            if (
              error instanceof Error &&
              (error.name === "InvalidParameterException" ||
                error.name === "NotAuthorizedException")
            ) {
              throw new Error(
                i18n.t("Amplify.SignIn.incorrect_email_or_password", { ns: "translation" }),
              );
            }

            throw error;
          });
      },
    }),
    [setupAuthObj],
  );

  if (displayNetworkError) {
    return (
      <div className="LogoContentCopyrightLayout">
        <Logo className="LoginLogo" />
        <FetchingErrorPage
          fetchingFunc={() => {
            setDisplayNetworkError(false);
          }}
          title={tErrorPages("title")}
          message={tErrorPages("message")}
          titleIcon="⚠️"
        />
        <Copyright />
      </div>
    );
  }

  if (displaySuccessfulPasswordChange) {
    return (
      <SuccessfulPasswordChange
        buttonAction={() => {
          setDisplaySuccessfulPasswordChange(false);
          toSignIn();
        }}
      />
    );
  }

  return (
    <AuthenticatorWrapper>
      {route === "confirmResetPassword" ? (
        <ConfirmResetPassword
          submit={forgotPasswordSubmit}
          errorCode={confirmResetPasswordErrorCode}
        />
      ) : (
        <Authenticator
          services={services}
          className="Login"
          hideSignUp
          components={components}
          formFields={formFields}
        >
          {children}
        </Authenticator>
      )}
    </AuthenticatorWrapper>
  );
};
