import { toastStore } from "config/toast";
import { useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { getImageSrc } from "utils/converters/getImageSrc";
import { deleteAvatarPicture, uploadPicture } from "utils/helpers/pictures";
import { PictureResolution } from "utils/helpers/pictures.model";
import { avatarCache } from "utils/machines/avatars";

const ActionType = {
  GetLatestSavedImage: "GetLatestSavedImage",
  SetLatestSavedImage: "SetLatestSavedImage",
  SetNewImage: "SetNewImage",
  DeleteAvatar: "DeleteAvatar",
  ResetToLatestSavedImage: "ResetToLatestSavedImage",
  UploadNewImage: "UploadNewImage",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
type ActionType = keyof typeof ActionType;

export interface AvatarState {
  _init: boolean;
  latestSavedImage: string | undefined;
  newImage: string | undefined;
  loading: boolean;
}

export interface AvatarAction {
  type: ActionType;
  payload?: Partial<AvatarState>;
}

export function reducer(state: AvatarState, action: AvatarAction): AvatarState {
  switch (action.type) {
    case "GetLatestSavedImage":
    case "UploadNewImage":
      return {
        ...state,
        loading: true,
        _init: false,
      };
    case "SetLatestSavedImage":
      return {
        ...state,
        latestSavedImage: action.payload?.latestSavedImage,
        loading: false,
        _init: false,
      };
    case "ResetToLatestSavedImage":
      return {
        latestSavedImage: state.latestSavedImage,
        newImage: "",
        loading: false,
        _init: false,
      };
    case "SetNewImage":
      return { ...state, newImage: action.payload?.newImage, loading: false, _init: false };

    default:
      throw new Error(`${action.type} case for AvatarState not implemented`);
  }
}

export const AVATAR_INIT_STATE: AvatarState = {
  _init: true,
  latestSavedImage: undefined,
  newImage: undefined,
  loading: false,
} as const;

export function useAvatarState() {
  const { t } = useTranslation("translation", { keyPrefix: "EditableFormHandles" });
  const [avatarState, dispatchAvatarAction] = useReducer(reducer, AVATAR_INIT_STATE);

  const loadAvatar = useCallback(async (organizationId: string, userEntityId: string) => {
    dispatchAvatarAction({ type: "GetLatestSavedImage" });

    dispatchAvatarAction({
      type: "SetLatestSavedImage",
      payload: {
        latestSavedImage: await avatarCache.getAvatarAsync(
          organizationId,
          userEntityId,
          "R320x320",
        ),
      },
    });
  }, []);

  const uploadAvatar = useCallback(
    async (organizationId: string, userEntityId: string) => {
      if (avatarState.newImage) {
        function showError() {
          toastStore.pushToast({
            expire: 5000,
            type: "error",
            msg: t("errorAvatarToast"),
          });
        }

        dispatchAvatarAction({ type: "UploadNewImage" });

        try {
          const r = await uploadPicture(avatarState.newImage, organizationId, userEntityId);
          if (!r.ok) {
            showError();
            return;
          }

          avatarCache.setAvatar(
            organizationId,
            userEntityId,
            new Set(Object.values(PictureResolution)).values(),
            getImageSrc(avatarState.newImage)!,
          );

          dispatchAvatarAction({
            type: "SetLatestSavedImage",
            payload: { latestSavedImage: avatarState.newImage },
          });
        } catch (error) {
          showError();
        }
      }
    },
    [avatarState.newImage, t],
  );

  const deleteAvatar = useCallback(
    async (organizationId: string, userEntityId: string, latestRowVersion: string) => {
      function showError() {
        dispatchAvatarAction({
          type: "ResetToLatestSavedImage",
        });
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: t("errorAvatarToast"),
        });
      }

      dispatchAvatarAction({ type: "UploadNewImage" });
      try {
        const r = await deleteAvatarPicture(organizationId, userEntityId, latestRowVersion);
        if (!r.ok) {
          showError();
          return;
        }
        // don't redownload the picture for 1 min
        avatarCache.delete(organizationId, userEntityId, 1 * 60 * 1000);
        dispatchAvatarAction({
          type: "SetLatestSavedImage",
          payload: { latestSavedImage: undefined },
        });
        dispatchAvatarAction({
          type: "SetNewImage",
          payload: { latestSavedImage: undefined },
        });
      } catch (error) {
        showError();
      }
    },
    [t],
  );

  return {
    avatarState,
    dispatchAvatarAction,
    loadAvatar,
    uploadAvatar,
    deleteAvatar,
  } as const;
}
