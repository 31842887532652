import { UserRoleType } from "api/models/UserRoleType";
import { IconType } from "libs/ui/Icon";

type RoleColors = "var(--yellow-500)" | "var(--primary-500)";

type RoleToColorMap = {
  [K in UserRoleType]?: RoleColors;
};

type RoleToIconMap = {
  [K in UserRoleType]?: IconType;
};

export function roleToColor(role?: UserRoleType): RoleColors {
  if (!role) {
    return "var(--primary-500)";
  }

  const roleMap: RoleToColorMap = {
    Admin: "var(--yellow-500)",
    Partner: "var(--primary-500)",
    Hcp: "var(--primary-500)",
  } as const;

  if (!roleMap[role]) {
    console.warn("Role to color map not found, returning default...");
  }

  return roleMap[role] ?? "var(--primary-500)";
}

export function roleToIcon(role: UserRoleType): IconType {
  const roleMap: RoleToIconMap = {
    Admin: "Admin",
    Partner: "User",
  } as const;

  if (!roleMap[role]) {
    console.warn("Role to icon map not found, returning default...");
  }
  return roleMap[role] ?? "Users";
}
