import { LoadableModel, modelToJSX } from "models/loadable";
import { useMemo } from "react";
import { Button } from "../Button";

import { Icon } from "../Icon";
import { Loader } from "../Loader";
import "./ReportList.scss";
import { DropDownToggler } from "../DropDownToggler";

interface ReportRowProps {
  title: string;
  description: string;
  report: LoadableModel<unknown>;
  timestamps: ReadonlyArray<{
    type: "Requested" | "Created" | "Download";
    date: Date;
    tooltip?: JSX.Element;
  }>;
}

export function ReportRow({ title, description, report, timestamps }: ReportRowProps) {
  const reportUI = useMemo(() => {
    return modelToJSX(report, {
      loading: (
        <div>
          <Loader inline loading loadingText="Preparing your file..." />
        </div>
      ),
      error: (
        <div className="ReportRow--error">
          <span>
            <Icon icon={"CircleError"} />
            Something went wrong
          </span>
          <span>&#x2022; Try Again</span>
        </div>
      ),
      loaded: (
        <Button
          className="ReportRow--button"
          buttonType={"primary"}
          buttonSize={"small"}
          buttonIcon={{ icon: "Download" }}
        >
          Download report
        </Button>
      ),
    });
  }, [report]);

  return (
    <div className="ReportRow">
      <div className="ReportRow--title">
        <Icon icon={"Results"} />
        {title}
      </div>
      <div className="ReportRow--description">&#x2022;{` ${description}`}</div>
      {reportUI}
      <DropDownToggler title="TimeStamps" className="ReportRow--timestamp">
        <>
          {timestamps.map(stamp => {
            return (
              <div
                className="ReportRow--timestamp-row"
                key={`${stamp.type} - ${stamp.date.toDateString()}`}
              >
                <span>{`${stamp.type} - ${stamp.date.toDateString()}`}</span>
                {stamp.tooltip}
              </div>
            );
          })}
        </>
      </DropDownToggler>
    </div>
  );
}

interface ReportListProps {
  reports: ReadonlyArray<ReportRowProps>;
}
export function ReportList({ reports }: ReportListProps) {
  return (
    <div className="ReportList">
      {reports.map((report, index) => {
        return <ReportRow key={`${report.title}${index}`} {...report} />;
      })}
    </div>
  );
}
