import { useMachine } from "@xstate/react";
import { FilterSideModalState } from "components/AppTables/common/Filter/model";
import { tableStateMachine } from "libs/ui/Table/model";
import { TableUtils } from "libs/ui/Table/utils";
import { useCallback } from "react";

type AnyTableService = ReturnType<
  typeof useMachine<ReturnType<typeof tableStateMachine<any, any>>>
>;

interface GenericPageTableContext<
  TableService extends AnyTableService,
  AvatarState,
  FilterSideModalS extends FilterSideModalState<any>,
> {
  tableService: TableService;
  avatarsState?: AvatarState;
  filterSideModelState?: FilterSideModalS;
}

export function usePagesParentTableConfig<
  TableService extends AnyTableService,
  AvatarState,
  FilterSideModalS extends FilterSideModalState<any>,
>(
  tableContext: GenericPageTableContext<TableService, AvatarState, FilterSideModalS>,
  // ! entity: keyof Omit<TableServiceDataType<TableService>, "limit" | "paginationToken">,
  // ! FIXME
  entity: any,
) {
  const [state, send] = tableContext.tableService;

  const hasData: boolean =
    state.context.data &&
    Array.isArray(state.context.data[entity]) &&
    state.context.data[entity].length > 0;
  const hasFilters = TableUtils.areFiltersApplied(state.context);

  const isTableEmpty = state.matches("loaded") && !hasData && !hasFilters;

  const onFilterToggle = useCallback(
    () => tableContext.filterSideModelState?.openFilterModal(),
    [tableContext],
  );

  const onSearchChange = useCallback(
    (value?: string) =>
      send({ type: "UPDATE_FILTERS", value: { ...state.context.filters, contains: value } }),
    [send, state.context.filters],
  );

  return { hasFilters, isTableEmpty, onFilterToggle, onSearchChange };
}
