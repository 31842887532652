import { catchError, defer, delay, from, retry, switchMap, tap, throwError } from "rxjs";
import { isBeErrorModel } from "utils/guards";

function getEventId(response: Response) {
  if (typeof response.headers.get === "function") {
    return response.headers.get("Location")?.split("/events/")[1] ?? "";
  }

  // e2e playwright
  if (typeof response.headers === "function") {
    return (response.headers as any)()["location"]?.split("/events/")[1];
  }
}

export function waitForQueryServiceRaw(
  promise: Promise<any>,
  endpointToCheckStatus: (id: string) => Promise<any>,
  setLoading?: (loading: boolean) => void,
  throwRawError = false,
) {
  return from(promise).pipe(
    delay(500),
    switchMap(postResponse => {
      return defer(() => from(endpointToCheckStatus(getEventId(postResponse)))).pipe(
        retry({ count: 3, delay: 2000 }),
        catchError(() => {
          return throwError(() => ({
            type: "OnEventStatusSubscribeError",
            code: "STATUS_QUERY_ERROR",
            message: "Failed to check event id status",
          }));
        }),
      );
    }),
    tap(() => {
      if (setLoading) {
        setLoading(false);
      }
    }),
    catchError(err => {
      if (throwRawError) {
        return throwError(() => err);
      }

      if (setLoading) {
        setLoading(false);
      }

      if (err.code === "STATUS_QUERY_ERROR") {
        return throwError(() => ({ ...err, type: "OnEventStatusSubscribeError" }));
      }

      if (err?.name === "ResponseError") {
        const response = err.response as Response;
        return from(response.json()).pipe(
          switchMap(responseJson => {
            if (isBeErrorModel(responseJson)) {
              return throwError(() => ({
                type: "OnEventStatusSubscribeError",
                code: "BE_ERROR",
                err: responseJson,
              }));
            }

            console.error(err);
            return throwError(() => ({
              type: "OnEventStatusSubscribeError",
              code: "ACTION_FAILED",
              message: "Promise failed",
              err,
            }));
          }),
        );
      }

      /**
       * Easier in case of e2e, sentry, qa to see what went wrong
       * If the stripping function or other throws
       */
      console.error(err);

      return throwError(() => ({
        type: "OnEventStatusSubscribeError",
        code: "ACTION_FAILED",
        message: "Promise failed",
        err,
      }));
    }),
  );
}
