import { useChartZoomingOptions } from "components/Chart/hooks/useChartZoomingOptions";
import { LineChartForwardedProps } from "components/Chart/MiniChart/model";
import { ECOption, Formatters, LineChartDataType, ZoomLevels } from "components/Chart/model";
import { TriggerOnLoad } from "components/SuspenseHelpers/TriggerOnLoad";
import { Card, Icon, IconType, Selection } from "libs/ui";
import { isLoaded } from "models/loadable";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { mainRoutePaths, RouteParams } from "router";

import { CarnaChartMeasurement } from "components/Chart/CarnaChartMeasurement";
import { getOptions as getOptionsBloodPressure } from "../BloodPressure/model";
import { getOptions as getOptionsBMI } from "../BMI/model";
import { getOptions as getOptionsEGFR } from "../EGFR/model";
import { getOptions as getOptionsGlucose } from "../Glucose/model";
import { getOptions as getOptionsHeight } from "../Height/model";
import { getOptions as getOptionsSerumCreatinine } from "../SerumCreatinine/model";
import { getOptions as getOptionsUACR } from "../UACR/model";
import { getOptions as getOptionsUrineAlbumin } from "../UrineAlbumin/model";
import { getOptions as getOptionsUrineCreatinine } from "../UrineCreatinine/model";
import { getOptions as getOptionsWeight } from "../Weight/model";
import { getOptions as getOptionsSQUACR } from "../SQUACR/model";

import { UnitsModel, UserGraphModel } from "api/query";
import { useGetMaxGlucoseValueFromRuleEngineUnit } from "components/Chart/hooks/useGetMaxGlucoseValueFromRuleEngineUnit";
import { useGetMaxHeightValueFromRuleEngineUnit } from "components/Chart/hooks/useGetMaxHeightValueFromRuleEngineUnit";
import { useGetMaxSerumCreatinineValueFromRuleEngineUnit } from "components/Chart/hooks/useGetMaxSerumCreatinineValueFromRuleEngineUnit";
import { useGetMaxUrineCreatinineValueFromRuleEngineUnit } from "components/Chart/hooks/useGetMaxUrineCreatinineValueFromRuleEngineUnit";
import { useGetMaxWeightValueFromRuleEngineUnit } from "components/Chart/hooks/useGetMaxWeightValueFromRuleEngineUnit";
import { useGetSerumCreatinineNormalRange } from "components/Chart/hooks/useGetSerumCreatinineNormalRange";
import { useGetUrineCreatinineNormalRange } from "components/Chart/hooks/useGetUrineCreatinineNormalRange";
import MiniChart from "components/Chart/MiniChart";
import { BLOOD_PRESSURE_INTERVAL_GRAPH_VALUE, BLOOD_PRESSURE_MAX_GRAPH_VALUE } from "config/const";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { useTranslation } from "react-i18next";
import { GraphMeasurements, useGraphDataAPIContext } from "../GraphDataAPIContext";
import { useHideClosestTick } from "../SerumCreatinine/useHideClosestTick";
import "./All.scss";
import { useGetEGFRRanges } from "utils/hooks/useGetEGFRRanges";
import { useGetBMIRanges } from "utils/hooks/useGetBMIRanges";
import { useGetUrineAlbuminRanges } from "utils/hooks/useGetUrineAlbuminRanges";
import { useGetUACRRanges } from "utils/hooks/useGetUACRRanges";

// const MiniChart = lazy(() => import("components/Chart/MiniChart"));

type Units =
  | "serumCreatinine"
  | "egfr"
  | "bloodPressure"
  | "glucose"
  | "urineCreatinine"
  | "urineAlbumin"
  | "uacr"
  | "weight"
  | "height"
  | "bmi"
  | "semiQuantitativeUacr";

function unitToDataTypeConvert(unit: Units): LineChartDataType {
  switch (unit) {
    case "glucose":
      return "Glucose";
    case "serumCreatinine":
      return "SerumCreatinine";
    case "egfr":
      return "Egfr";
    case "bloodPressure":
      return "BloodPressure";
    case "urineCreatinine":
      return "UrineCreatinine";
    case "urineAlbumin":
      return "UrineAlbumin";
    case "uacr":
      return "UACR";
    case "bmi":
      return "BMI";
    case "height":
      return "Height";
    case "weight":
      return "Weight";
    case "semiQuantitativeUacr":
      return "SemiQuantitativeUACR";

    default:
      throw Error(`${unit} unit is not conversable to CarnaLineChart`);
  }
}

function getIcon(unit: LineChartDataType): IconType {
  switch (unit) {
    case "SerumCreatinine":
    case "Egfr":
      return "CreatinineEgfr";

    case "UACR":
    case "UrineCreatinine":
    case "UrineAlbumin":
    case "SemiQuantitativeUACR":
      return "Uacr";

    case "Glucose":
      return "Glucose";

    case "BMI":
    case "Height":
    case "Weight":
      return "Bmi";

    case "BloodPressure":
      return "BloodPressure";

    default:
      throw new Error(`${unit} not implemented`);
  }
}

const implementedLineCharts: (keyof UnitsModel)[] = [
  "serumCreatinine",
  "egfr",
  "glucose",
  "bloodPressure",
  "bmi",
  "height",
  "weight",
  "urineCreatinine",
  "urineAlbumin",
  "uacr",
  "semiQuantitativeUacr",
];

const implementedLineChartsWithLegend: (keyof UnitsModel)[] = [
  "egfr",
  "urineAlbumin",
  "uacr",
  "bmi",
  "semiQuantitativeUacr",
];

const INIT_GRAPH_MODEL: UserGraphModel = {
  genderType: "Male",
  id: "",
  measurements: [],
  roleType: "Admin",
};

export function All() {
  const { t } = useTranslation("translation", { keyPrefix: "PatientDetails.Graph.tabs.All" });
  const navigate = useNavigate();
  const { organizationId = "", patientId = "" } = useParams<RouteParams["patientGraph"]>();
  const { data, getGraphData } = useGraphDataAPIContext();
  const { appConfig } = useGlobalConfigContext();
  const { orderedEGFRStageRanges } = useGetEGFRRanges();
  const { orderedBMIStageRanges } = useGetBMIRanges();
  const { orderedUrineAlbuminStageRanges } = useGetUrineAlbuminRanges();
  const { orderedUACRStageRanges } = useGetUACRRanges();

  const chartRefs = useRef<(LineChartForwardedProps | null)[]>([]);

  const [currentSelectedZoomLevel, setCurrentSelectedZoomLevel, zoomOptions, onZoomChange] =
    useChartZoomingOptions();

  const goTo = useCallback(
    (path: keyof UnitsModel) =>
      navigate(
        generatePath(`${mainRoutePaths.patientGraphs}/${path}`, {
          patientId,
          organizationId,
        }),
      ),
    [navigate, organizationId, patientId],
  );

  const creatinineNormalRange = useGetSerumCreatinineNormalRange();
  const hideClosesTick = useHideClosestTick();
  const maxCreatinineRange = useGetMaxSerumCreatinineValueFromRuleEngineUnit();

  const urineCreatinineNormalRange = useGetUrineCreatinineNormalRange();
  const maxUrineCreatinineRange = useGetMaxUrineCreatinineValueFromRuleEngineUnit();

  const { value: maxGlucoseRange } = useGetMaxGlucoseValueFromRuleEngineUnit();
  const { value: maxHeightRange } = useGetMaxHeightValueFromRuleEngineUnit();
  const { value: maxWeightRange } = useGetMaxWeightValueFromRuleEngineUnit();

  const {
    serumCreatinineUnit,
    urineCreatinineUnit,
    glucoseUnit,
    heightUnit,
    weightUnit,
    urineAlbuminUnit,
    bloodPressureUnit,
    egfrUnit,
    uacrUnit,
  } = useGetUserPreferredMeasurementUnits();

  const getLineChartInitOptions = useCallback(
    (unit: keyof UnitsModel, formatters: Formatters) => {
      switch (unit) {
        case "glucose":
          return getOptionsGlucose({
            formatters,
            data: isLoaded(data.Glucose) ? data.Glucose.value : INIT_GRAPH_MODEL,
            measurementUnit: glucoseUnit,
            maxRange: maxGlucoseRange,
            showTrending: appConfig?.components.graphs.Patient.Index.showTrending,
          });
        case "serumCreatinine":
          return getOptionsSerumCreatinine({
            formatters,
            data: isLoaded(data.SerumCreatinine) ? data.SerumCreatinine.value : INIT_GRAPH_MODEL,
            measurementUnit: serumCreatinineUnit,
            maxCreatinineRange,
            creatinineNormalRange,
            showTrending: appConfig?.components.graphs.Patient.Index.showTrending,
          });

        case "egfr":
          return getOptionsEGFR(
            formatters,
            isLoaded(data.Egfr) ? data.Egfr.value : INIT_GRAPH_MODEL,
            orderedEGFRStageRanges,
            appConfig?.components.graphs.Patient.Index.showTrending,
          );

        case "urineCreatinine":
          return getOptionsUrineCreatinine({
            formatters,
            data: isLoaded(data.UrineCreatinine) ? data.UrineCreatinine.value : INIT_GRAPH_MODEL,
            measurementUnit: urineCreatinineUnit,
            urineCreatinineNormalRange,
            maxRange: maxUrineCreatinineRange,
            showTrending: appConfig?.components.graphs.Patient.Index.showTrending,
          });

        case "bloodPressure":
          return getOptionsBloodPressure({
            formatters,
            graphUser: isLoaded(data.BloodPressure) ? data.BloodPressure.value : INIT_GRAPH_MODEL,
            zoomLevel: currentSelectedZoomLevel,
            showTrending: appConfig?.components.graphs.Patient.Index.showTrending,
            yAxis: {
              max: BLOOD_PRESSURE_MAX_GRAPH_VALUE,
              interval: BLOOD_PRESSURE_INTERVAL_GRAPH_VALUE,
            },
          });

        case "urineAlbumin":
          return getOptionsUrineAlbumin(
            formatters,
            isLoaded(data.UrineAlbumin) ? data.UrineAlbumin.value : INIT_GRAPH_MODEL,
            urineAlbuminUnit,
            orderedUrineAlbuminStageRanges,
            appConfig?.components.graphs.Patient.Index.showTrending,
          );

        case "uacr":
          return getOptionsUACR(
            formatters,
            isLoaded(data.UACR) ? data.UACR.value : INIT_GRAPH_MODEL,
            orderedUACRStageRanges,
            appConfig?.components.graphs.Patient.Index.showTrending,
          );

        case "bmi":
          return getOptionsBMI(
            formatters,
            isLoaded(data.BMI) ? data.BMI.value : INIT_GRAPH_MODEL,
            orderedBMIStageRanges,
            appConfig?.components.graphs.Patient.Index.showTrending,
          );

        case "height":
          return getOptionsHeight({
            formatters,
            data: isLoaded(data.Height) ? data.Height.value : INIT_GRAPH_MODEL,
            measurementUnit: heightUnit,
            maxRange: maxHeightRange,
            showTrending: appConfig?.components.graphs.Patient.Index.showTrending,
          });

        case "weight":
          return getOptionsWeight({
            formatters,
            data: isLoaded(data.Weight) ? data.Weight.value : INIT_GRAPH_MODEL,
            measurementUnit: weightUnit,
            maxRange: maxWeightRange,
            showTrending: appConfig?.components.graphs.Patient.Index.showTrending,
          });

        case "semiQuantitativeUacr":
          return getOptionsSQUACR(
            formatters,
            isLoaded(data.SemiQuantitativeUACR)
              ? data.SemiQuantitativeUACR.value
              : INIT_GRAPH_MODEL,
            orderedUACRStageRanges,
            appConfig?.components.graphs.Patient.Index.showTrending,
          );

        default:
          throw new Error(`Init options for ${unit} not implemented`);
      }
    },
    [
      data.Glucose,
      data.SerumCreatinine,
      data.Egfr,
      data.UrineCreatinine,
      data.BloodPressure,
      data.UrineAlbumin,
      data.UACR,
      data.BMI,
      data.Height,
      data.Weight,
      data.SemiQuantitativeUACR,
      glucoseUnit,
      maxGlucoseRange,
      appConfig?.components.graphs.Patient.Index.showTrending,
      serumCreatinineUnit,
      maxCreatinineRange,
      creatinineNormalRange,
      orderedEGFRStageRanges,
      urineCreatinineUnit,
      urineCreatinineNormalRange,
      maxUrineCreatinineRange,
      currentSelectedZoomLevel,
      urineAlbuminUnit,
      orderedUrineAlbuminStageRanges,
      orderedUACRStageRanges,
      orderedBMIStageRanges,
      heightUnit,
      maxHeightRange,
      weightUnit,
      maxWeightRange,
    ],
  );

  const getOptions = useCallback(
    (unit: keyof UnitsModel) =>
      (formatters: Formatters): ECOption => {
        const initOptions = getLineChartInitOptions(unit, formatters);

        return {
          ...initOptions,
          dataZoom: {
            show: false,
            disabled: true,
            bottom: 0,
            filterMode: "none",
          },
          grid: { left: 40, top: 10, right: 30, bottom: 40 },
          ...(implementedLineChartsWithLegend.some(el => el === unit)
            ? { visualMap: { ...initOptions.visualMap, top: -100 } }
            : undefined),
        };
      },
    [getLineChartInitOptions],
  );

  const getMeasurementUnit = useCallback(
    (unit: keyof UnitsModel) => {
      switch (unit) {
        case "glucose":
          return glucoseUnit;
        case "serumCreatinine":
          return serumCreatinineUnit;
        case "urineCreatinine":
          return urineCreatinineUnit;
        case "height":
          return heightUnit;
        case "weight":
          return weightUnit;
        case "urineAlbumin":
          return urineAlbuminUnit;
        case "bloodPressure":
          return bloodPressureUnit;
        case "egfr":
          return egfrUnit;
        case "uacr":
        case "semiQuantitativeUacr":
          return uacrUnit;
        default:
          return undefined;
      }
    },
    [
      bloodPressureUnit,
      egfrUnit,
      glucoseUnit,
      heightUnit,
      serumCreatinineUnit,
      uacrUnit,
      urineAlbuminUnit,
      urineCreatinineUnit,
      weightUnit,
    ],
  );

  const onSelect = useCallback(
    (val: ZoomLevels = "custom") => {
      setCurrentSelectedZoomLevel(val);
      chartRefs.current.forEach(lineChart => {
        if (lineChart?.forwardedChartRef?.current) {
          onZoomChange(lineChart.forwardedChartRef.current, val, new Date().toString());
        }
      });
    },
    [onZoomChange, setCurrentSelectedZoomLevel],
  );

  const isLoadedAndWithAnyValue = Object.keys(data).some(key =>
    isLoaded(data[key as keyof GraphMeasurements])
      ? data[key as keyof GraphMeasurements].value?.measurements?.length > 0
      : false,
  );

  const onDataListLoad = useCallback(() => {
    if (isLoadedAndWithAnyValue) {
      onSelect("month");
    }
  }, [isLoadedAndWithAnyValue, onSelect]);

  const onLoad = useMemo(() => [onDataListLoad], [onDataListLoad]);

  useEffect(() => {
    const implementedDataTypes = implementedLineCharts.map(chart => unitToDataTypeConvert(chart));

    // if (implementedDataTypes.every(type => isDefaultModel(data[type]))) {
    /**
     * !! for rest of tabs
     * !! logic for fetching is in GraphTabsWithDataDownload
     * !! this component is heavy
     */
    getGraphData(organizationId, patientId, implementedDataTypes);
    // }
  }, [getGraphData, organizationId, patientId]);

  return (
    <div className="All">
      <Selection
        icon="Calendar"
        disabled={!isLoadedAndWithAnyValue}
        options={zoomOptions}
        value={currentSelectedZoomLevel}
        onSelect={onSelect}
        className="All__selection"
      />
      <div className="All__body">
        {implementedLineCharts.map((chart, index) => (
          <Card onClick={() => goTo(chart)} className="All__card" key={chart}>
            <h4 className="All__title">
              <Icon icon={getIcon(unitToDataTypeConvert(chart))} />
              {t(`cardTitle${chart}`)}
            </h4>
            <CarnaChartMeasurement measurementUnit={getMeasurementUnit(chart)} />
            <div className="All__chart">
              <TriggerOnLoad callbacks={onLoad}>
                <MiniChart
                  data={data[unitToDataTypeConvert(chart)]}
                  dataType={unitToDataTypeConvert(chart)}
                  afterInit={hideClosesTick}
                  getOptions={getOptions(chart)}
                  key={chart}
                  ref={ref => (chartRefs.current[index] = ref)}
                />
              </TriggerOnLoad>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}
