import { useMachine } from "@xstate/react";
import { UserDetailsResponseModel } from "api/query";
import { UserModel } from "components/Forms/User/model";

import { CarnaApiEvent } from "config/apiEvent";
import { CarnaApiQuery } from "config/apiQuery";
import { NEW_BACKEND_NULL } from "config/NEW_BACKEND";
import { toastStore } from "config/toast";
import i18n from "i18next";
import { useMemo } from "react";
import { getRowVersion } from "utils/helpers/getRowVersion";

import { stripNetworkBodyWith } from "components/Forms/helper";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { firstValueFrom } from "rxjs";
import { isAdmin as isRoleAdmin } from "utils/helpers/roles";
import {
  OnEventStatusSubscribeError,
  waitForQueryService,
} from "utils/hooks/useOnEventStatusSubscribe";
import { makeDetailsPageStateMachine } from "utils/machines/pages/details/makeDetailsPageStateMachine";
import { assign, fromPromise } from "xstate";

import {
  UpdateAdminRequestModelForStripping,
  UpdatePartnerRequestModelForStripping,
} from "config/binding";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { isEqual } from "lodash-es";

export interface DetailsFormProps {
  service: ReturnType<typeof useMakeUserPagesDetailState>;
  userId: string;
  organizationId: string;
  onClose?: () => void;
}

const successToast = () =>
  toastStore.pushToast({
    expire: 5000,
    type: "success",
    msg: i18n.t("UserDetails.successPutToast", { ns: "translation" }),
  });

// ! FIXME add xstate error event model type
const errorPutToast = (input: any) => {
  const err = input.event.error as OnEventStatusSubscribeError;

  if (err.type === "OnEventStatusSubscribeError") {
    switch (err.code) {
      case "BE_ERROR":
        showBeFieldErrors(err.err, i18n.t("UserDetails.errorPutToast", { ns: "translation" }));

        break;
      case "ACTION_FAILED":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("UserDetails.errorPutToast", { ns: "translation" }),
        });
        break;
      case "STATUS_QUERY_ERROR":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("UserDetails.queryServiceError", { ns: "translation" }),
        });
    }
    return;
  }

  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("UserDetails.errorPutToast", { ns: "translation" }),
  });
};

const errorFetchToast = () => {
  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("UserDetails.errorFetchToast", { ns: "translation" }),
  });
};

export function useMakeUserPagesDetailState(userId: string, organizationId: string) {
  const userAttributes = useAdditionalAuthInformationContext();

  const userPageStateMachine = useMemo(
    () =>
      makeDetailsPageStateMachine<
        UserDetailsResponseModel,
        UserModel
        // DetailsPageServiceList<typeof fetchUserData, typeof saveUser, typeof saveUserStatus>
      >(),
    [],
  );

  const service = useMachine(
    userPageStateMachine.provide({
      actors: {
        saveData: fromPromise(async ({ input }) => {
          let result: Response | undefined;

          if (isEqual(input.context.data, input.event.value) === false) {
            const { context, event } = input;
            const { id = "", organizationId = "", role = undefined } = context.data ?? {};

            const putUser =
              userAttributes.isAdmin && isRoleAdmin(role)
                ? CarnaApiEvent.Admin.put(
                    {
                      organizationId: organizationId,
                      userEntityId: id,
                      replaceAdminRequestModel: {
                        ...event.value,
                        rowVersion: event.value.rowVersion ?? "none",
                      },
                    },
                    stripNetworkBodyWith(UpdateAdminRequestModelForStripping),
                  )
                : CarnaApiEvent.Partner.put(
                    {
                      organizationId: organizationId,
                      userEntityId: id,
                      replacePartnerRequestModel: {
                        ...event.value,
                        rowVersion: event.value.rowVersion ?? "none",
                      },
                    },
                    stripNetworkBodyWith(UpdatePartnerRequestModelForStripping),
                  );

            await firstValueFrom(waitForQueryService(putUser));

            result = await putUser;
          }

          if (input.event.saveAvatar) {
            await input.event.saveAvatar();
          }

          return result
            ? {
                ...NotNullOrUndefined(input.context.data),
                ...input.event.value,
                ...getRowVersion(result),
              }
            : input.context.data;
        }),
        fetchData: fromPromise(async () => {
          return userAttributes.isAdmin
            ? CarnaApiQuery.Users.getById({ id: userId })
            : CarnaApiQuery.Partners.getById({ organizationId, id: userId });
        }),
        saveStatus: fromPromise(async () => {
          return NEW_BACKEND_NULL;
        }),
      },
      actions: {
        dataSaved: input => {
          const event: any = input.event;

          // If the current user edites itself
          if (
            event?.output?.id === userAttributes.currentUserId &&
            (event?.output?.firstName !== userAttributes.firstName ||
              event?.output?.lastName !== userAttributes.lastName)
          ) {
            userAttributes.setUserAttributes?.({
              ...userAttributes,
              firstName: event?.output?.firstName ?? "",
              lastName: event?.output?.lastName ?? "",
            });
          }
          successToast();
        },
        savingFailed: errorPutToast,
        failedToLoadData: errorFetchToast,
        loadSavedData: assign({
          data: input => {
            return (input.event as any).output;
          },
        }),
        loadEntityData: assign({
          data: input => {
            return (input.event as any).output;
          },
        }),
        updateSaveStatus: assign({
          data: ({ context, event }) => {
            return {
              ...context.data,
              status: (event as any).output?.status ? "Active" : "Deactivated",
            } as UserDetailsResponseModel;
          },
        }),
        refreshTable: () => {},
      },
    }),
  );

  return service;
}
