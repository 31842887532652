import { Icon } from "libs/ui";
import classNames from "classnames";
import "./CollapseToggler.scss";

export interface CollapseTogglerProps {
  toggleCollapsed: boolean;
  onToggleCollapse: () => void;
  className?: string;
}

export function CollapseToggler({ toggleCollapsed, onToggleCollapse, className}: CollapseTogglerProps) {
  return (
    <button className={classNames("CollapseToggler", className)} onClick={onToggleCollapse}>
      <Icon icon={toggleCollapsed ? "ChevronRight" : "ChevronLeft"} />
    </button>
  );
}
