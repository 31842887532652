import { UserGraphModel } from "api/query/models/UserGraphModel";
import CarnaLineChart from "components/Chart/CarnaChart/LineChart";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useCallback } from "react";
import { getOptions } from "./model";
import { useGetUACRRanges } from "utils/hooks/useGetUACRRanges";
import { Formatters } from "components/Chart/model";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";

export function SQUACR() {
  const { appConfig } = useGlobalConfigContext();
  const { globalPreference } = useGlobalPreferenceContext();
  const { uacrUnit } = useGetUserPreferredMeasurementUnits();
  const { orderedUACRStageRanges } = useGetUACRRanges();

  const getOptionsWithTrend = useCallback(
    (formatters: Formatters, graphUser: UserGraphModel) => {
      return getOptions(
        formatters,
        graphUser,
        orderedUACRStageRanges,
        appConfig?.components.graphs.Patient.Index.showTrending,
        globalPreference?.regionType,
      );
    },
    [
      appConfig?.components.graphs.Patient.Index.showTrending,
      globalPreference?.regionType,
      orderedUACRStageRanges,
    ],
  );

  return (
    <CarnaLineChart
      showTrend={appConfig?.components.graphs.Patient.Index.showTrending}
      dataType="SemiQuantitativeUACR"
      unitType="semiQuantitativeUacr"
      className="SQUACR"
      getOptions={getOptionsWithTrend}
      measurementUnit={uacrUnit}
    />
  );
}

export default SQUACR;
