import { Page } from "libs/ui";
import { useTranslation } from "react-i18next";
// import { EventsLog } from "./tabs/EventsLog";
import classNames from "classnames";
import { TabsForRouter } from "libs/ui/TabsForRouter";
import { reportsRoutePaths } from "router";
import { useBreadCrumbs } from "utils/hooks/Pages/useBreadCrumbs";

export function ReportsPage() {
  const breadCrumbs = useBreadCrumbs();

  const { t } = useTranslation("translation", { keyPrefix: "ReportsPage" });

  return (
    <Page {...(breadCrumbs && { breadCrumbs })} className={classNames("ReportsPage")}>
      <TabsForRouter
        tabs={[
          {
            label: t("tabs.request_new"),
            routePath: reportsRoutePaths.requestNew.substring(1),
          },
          {
            label: t("tabs.all_reports"),
            routePath: reportsRoutePaths.allReports.substring(1),
          },
        ]}
      />
    </Page>
  );
}
