import { EventResponseModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { useCallback, useState } from "react";
import { waitForQueryServiceRaw } from "./waitForQueryServiceRaw";
import { CommonProblemDetailsModel } from "api/event/models/CommonProblemDetailsModel";

export type OnEventStatusSubscribeError =
  | {
      type: "OnEventStatusSubscribeError";
      code: "ACTION_FAILED" | "STATUS_QUERY_ERROR";
      message: string;
    }
  | {
      type: "OnEventStatusSubscribeError";
      code: "BE_ERROR";
      err: CommonProblemDetailsModel;
    };

export interface SubscribeParams {
  error: (err: OnEventStatusSubscribeError) => void;
  complete: () => void;
}

export function waitForQueryService(
  promise: Promise<any>,
  setLoading?: (loading: boolean) => void,
  eventStatusEndpoint: (id: string) => Promise<EventResponseModel> = CarnaApiQuery.Event.get,
) {
  return waitForQueryServiceRaw(promise, eventStatusEndpoint, setLoading);
}

export function useOnEventStatusSubscribe() {
  const [isLoading, setIsLoading] = useState(false);

  const action = useCallback(
    (
      promise: Promise<any>,
      subscribeParams: Partial<SubscribeParams> | ((value: EventResponseModel) => void),
      eventStatusEndpoint?: (id: string) => Promise<EventResponseModel>,
    ) => {
      setIsLoading(true);

      const subscription = waitForQueryService(
        promise,
        setIsLoading,
        eventStatusEndpoint,
      ).subscribe(subscribeParams);

      return () => subscription.unsubscribe();
    },
    [],
  );

  return [action, isLoading] as const;
}
