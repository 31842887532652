import { useCallback, useState } from "react";
import { LocalStorageKey, tryGetLocalStorageValue } from "../localStorage";

export function useLocalStorage<T>(key: LocalStorageKey) {
  const [storageValue, setStorageValue] = useState(tryGetLocalStorageValue<T>(key));

  const setLocalStorageItem = useCallback(
    (value: T) => {
      localStorage.setItem(key, JSON.stringify(value));
      setStorageValue(value);
    },
    [key],
  );

  const removeLocalStorageItem = useCallback(() => {
    localStorage.removeItem(key);
    setStorageValue(undefined);
  }, [key]);

  return { storageValue, setLocalStorageItem, removeLocalStorageItem, tryGetLocalStorageValue };
}
