import { PickingInfo } from "@deck.gl/core";
import { Trans, useTranslation } from "react-i18next";
import "./MapTooltip.scss";
import { ColorDot } from "libs/ui";
import { MapData } from "pages/DashboardPage/DashboardMap/DashboardMapReportDataContext";

interface TooltipProps {
  info: PickingInfo<MapData>;
}

export function MapTooltip({ info }: Readonly<TooltipProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.TotalNumbers" });
  const { t: tCountry } = useTranslation("nationalities", { keyPrefix: "countries" });

  const { object, x, y } = info;

  if (!object) {
    return null;
  }

  const isCountry = object.name === object.entityCountryISO;

  return (
    <div
      className="MapTooltip"
      style={{
        left: x + 5,
        top: y + 5,
      }}
    >
      <p className="MapTooltip__title">
        {isCountry ? tCountry(object.entityCountryISO ?? object.name) : object.name}
      </p>
      <p className="MapTooltip__stat">
        <ColorDot type="primary" />

        <Trans i18nKey={"totalPatients"} t={t} values={{ value: object.patientCount }}>
          <span style={{ whiteSpace: "pre-wrap" }}></span>
          <strong></strong>
        </Trans>
      </p>
      <p className="MapTooltip__stat">
        <ColorDot type="secondary" />

        <Trans i18nKey={object.reportType} t={t} values={{ value: object.patientRiskCount }}>
          <span style={{ whiteSpace: "pre-wrap" }}></span>
          <strong></strong>
        </Trans>
      </p>
    </div>
  );
}
