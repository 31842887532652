/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DeviceType = {
    None: 'None',
    NovaMaxProCreateEgfrMeter: 'NovaMaxProCreateEgfrMeter'
} as const;
export type DeviceType = typeof DeviceType[keyof typeof DeviceType];


export function DeviceTypeFromJSON(json: any): DeviceType {
    return DeviceTypeFromJSONTyped(json, false);
}

export function DeviceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceType {
    return json as DeviceType;
}

export function DeviceTypeToJSON(value?: DeviceType | null): any {
    return value as any;
}

