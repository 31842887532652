import { TestsPerDeviceTable } from "components/AppTables/TestsPerDeviceTable";
import { DetailsPage } from "components/PageTemplate/DetailsPage";
import { Chip, resolveStatusToVariant, Tabs, Toolbar } from "libs/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { RouteParams } from "router/model";
import { DetailsForm } from "./DetailsForm";
import { useBreadcrumbsToSelectedDevice, useMakeDevicesPageDetailState } from "./model";

function useOnLocationChange(detailsService: ReturnType<typeof useMakeDevicesPageDetailState>) {
  const location = useLocation();
  const [, send] = detailsService;

  useEffect(() => {
    send({ type: "LOAD_DATA" });
  }, [location, send]);
}

export function Details() {
  const { t } = useTranslation("translation", { keyPrefix: "DeviceDetails" });
  const { t: tToolbar } = useTranslation("translation", { keyPrefix: "Toolbar" });
  const detailsService = useMakeDevicesPageDetailState();
  const [state, send] = detailsService;
  const { organizationId, deviceId } = useParams<RouteParams["deviceDetails"]>();
  useOnLocationChange(detailsService);
  useBreadcrumbsToSelectedDevice(detailsService);

  const toggleDeviceInfo = () =>
    send({ type: state.matches("info.show") ? "HIDE_INFO" : "SHOW_INFO" });

  return (
    <DetailsPage
      details={<DetailsForm detailsService={detailsService} />}
      controls={
        <Toolbar
          data-testid="controls-toolbar"
          data-loading={state.matches("entity.loading")}
          data-loaded={state.matches("entity.loaded")}
          buttons={[
            {
              icon: "Info",
              variant: "grey",
              onClick: toggleDeviceInfo,
              active: state.matches("info.show"),
              tooltip: tToolbar("info.Device"),
            },
          ]}
        />
      }
      showInfo={state.matches("info.show")}
      customBreadcrumbsElem={
        <Chip
          loading={state.matches("entity.loading")}
          size="small"
          variant={resolveStatusToVariant(state?.context?.data?.status)}
        >
          {state.context.data?.status?.toUpperCase()}
        </Chip>
      }
    >
      <Tabs
        tabs={[
          {
            label: t("tab.labelTests"),
            Component: (
              <TestsPerDeviceTable
                organizationId={organizationId ?? ""}
                deviceId={deviceId ?? ""}
              />
            ),
          },
        ]}
      />
    </DetailsPage>
  );
}
