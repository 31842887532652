import { useMachine } from "@xstate/react";
import { stripNetworkBodyWith } from "components/Forms/helper";
import { NEW_BACKEND_NULL } from "config/NEW_BACKEND";
import { CarnaApiEvent } from "config/apiEvent";
import { CarnaApiQuery } from "config/apiQuery";
import { UpdatePatientRequestModelForStripping } from "config/binding";
import { toastStore } from "config/toast";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import i18n from "i18next";
import { isEqual } from "lodash-es";
import { PatientModel } from "models/PersonModels";
import { useMemo } from "react";
import { firstValueFrom } from "rxjs";
import { BEDateOfBirthToDate, getDBDateFormat } from "utils/converters/getDBDateFormat";
import { getRowVersion } from "utils/helpers/getRowVersion";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import {
  OnEventStatusSubscribeError,
  waitForQueryService,
} from "utils/hooks/useOnEventStatusSubscribe";
import { makeDetailsPageStateMachine } from "utils/machines/pages/details/makeDetailsPageStateMachine";
import { assign, fromPromise } from "xstate";
import { useOverviewAPIUnsafeContext } from "./Overview/OverviewAPIContext";

const successToast = () =>
  toastStore.pushToast({
    expire: 5000,
    type: "success",
    msg: i18n.t("PatientDetails.successPutToast", { ns: "translation" }),
  });

// ! FIXME add xstate error event model type
const errorPutToast = (input: any) => {
  const err = input.event.error as OnEventStatusSubscribeError;

  if (err.type === "OnEventStatusSubscribeError") {
    switch (err.code) {
      case "BE_ERROR":
        showBeFieldErrors(err.err, i18n.t("PatientDetails.errorPutToast", { ns: "translation" }));

        break;
      case "ACTION_FAILED":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("PatientDetails.errorPutToast", { ns: "translation" }),
        });
        break;
      case "STATUS_QUERY_ERROR":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("PatientDetails.queryServiceError", { ns: "translation" }),
        });
    }
    return;
  }

  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("PatientDetails.errorPutToast", { ns: "translation" }),
  });
};

const errorFetchToast = () => {
  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("PatientDetails.errorFetchToast", { ns: "translation" }),
  });
};

export function useMakePatientDetailState(patientId: string, organizationId: string) {
  const userAttributes = useAdditionalAuthInformationContext();
  const { getWidgetData } = useOverviewAPIUnsafeContext() ?? {};

  const patientPageStateMachine = useMemo(
    () =>
      makeDetailsPageStateMachine<
        PatientModel,
        PatientModel
        // DetailsPageServiceList<
        //   typeof fetchPatientData,
        //   typeof savePatient,
        //   typeof savePatientStatus
        // >
      >(),
    [],
  );

  const service = useMachine(
    patientPageStateMachine.provide({
      actors: {
        saveData: fromPromise(async ({ input }) => {
          let result: Response | undefined;

          if (isEqual(input.context.data, input.event.value) === false) {
            const putPatient = CarnaApiEvent.Patient.put(
              {
                organizationId: input.event.value.organizationId,
                userEntityId: input.context.data?.id ?? "",
                updatePatientRequestModel: {
                  ...input.event.value,
                  dateOfBirth: getDBDateFormat(input.event.value.dateOfBirth),
                  rowVersion: input.event.value.rowVersion,
                  ancestry: input.event.value.ancestry ?? undefined,
                  nationalityType: input.event.value.nationalityType ?? undefined,
                },
              },
              stripNetworkBodyWith(UpdatePatientRequestModelForStripping),
            );

            await firstValueFrom(waitForQueryService(putPatient));

            result = await putPatient;
          }

          if (input.event.saveAvatar) {
            await input.event.saveAvatar();
          }

          return (
            result
              ? {
                  ...input.context.data,
                  ...input.event.value,
                  dateOfBirth: input.event.value.dateOfBirth as any,
                  ...getRowVersion(result),
                }
              : input.context.data
          ) as PatientModel;
        }),
        fetchData: fromPromise(async () => {
          const result = CarnaApiQuery.Patients.getById({
            organizationId,
            patientEntityId: patientId,
          });
          const data = await result;

          return { ...data, dateOfBirth: BEDateOfBirthToDate(data.dateOfBirth) } as any;
        }),
        saveStatus: fromPromise(async () => {
          return NEW_BACKEND_NULL;
        }),
        // saveData: savePatient,
        // fetchData: fetchPatientData,
        // saveStatus: savePatientStatus,
      },
      actions: {
        dataSaved: input => {
          const event: any = input.event;

          // If the current user edites itself
          if (
            event?.output?.id === userAttributes.currentUserId &&
            (event?.output?.firstName !== userAttributes.firstName ||
              event?.output?.lastName !== userAttributes.lastName)
          ) {
            userAttributes.setUserAttributes?.({
              ...userAttributes,
              firstName: event?.output?.firstName ?? "",
              lastName: event?.output?.lastName ?? "",
            });
          }
          getWidgetData?.(event?.output?.organizationId, event?.output?.id, ["PatientInfo"]);
          successToast();
        },
        savingFailed: errorPutToast,
        failedToLoadData: errorFetchToast,
        loadSavedData: assign({
          data: input => {
            return (input.event as any)?.output;
          },
        }),
        loadEntityData: assign({
          data: input => {
            return (input.event as any)?.output;
          },
        }),
        updateSaveStatus: assign({
          data: context => {
            return {
              ...context.context,
              // status: event.data?.data?.isActive ? "Active" : "Deactivated",
            } as PatientModel;
          },
        }),
        refreshTable: () => {},
      },
    }),
  );

  return service;
}
