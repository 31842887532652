import { autoUpdate, useFloating } from "@floating-ui/react-dom";
import { Icon } from "../Icon";
import { useCallback, useState } from "react";
import "./TimestampTooltip.scss";
import { Avatar, AvatarType } from "../Avatar";
interface TimestampTooltipProps {
  username: string;
  avatarType?: AvatarType;
  avatarSrc?: string;
}
export function TimestampTooltip({ username, avatarType, avatarSrc }: TimestampTooltipProps) {
  const [isOpen, setIsOpen] = useState(false);

  const onMouseEnter = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsOpen(false);
  }, []);

  const { refs, floatingStyles } = useFloating({
    open: isOpen,
    placement: "left",
    whileElementsMounted: autoUpdate,
  });

  return (
    <>
      <Icon
        icon={"Info"}
        svgProps={{ width: "20", height: "20" }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={refs.setReference}
      />
      {isOpen ? (
        <div className="TimestampTooltip" ref={refs.setFloating} style={floatingStyles}>
          <Avatar
            size="medium"
            src={avatarSrc}
            avatarLoading={false}
            firstName={username}
            avatarType={avatarType}
          />
          <span>{username}</span>
        </div>
      ) : null}
    </>
  );
}
