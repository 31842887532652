import { AncestryType } from "api/query/models/AncestryType";
import { NationalityType } from "api/query/models/NationalityType";
import { validateRegistryNumber } from "components/Forms/Patient/patientFormUtils";
import { isReadonly } from "components/Forms/helper";
import { useOnInputValidation } from "components/Forms/hooks";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import i18n from "i18next";
import { isValidPhoneNumber } from "libphonenumber-js";
import {
  DatePickerFieldLazy,
  Input,
  SelectFilter,
  SelectFilterResult,
  Selection,
  SideModalElements,
} from "libs/ui";
import { GenderType, UserRoleType, UserStatusType } from "models/PersonModels";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConfigFieldModel, RoleEntities } from "utils/createGlobalConfigStore";
import { useRegistryNumberLabel } from "utils/hooks/useRegistryNumberLabel";
import { useValidateDate } from "utils/hooks/useValidateDate";
import { ancestryTypeToSelectOptions } from "utils/mappers/ancestryTypeToSelectOptions";
import { genderTypeToSelectOptions } from "utils/mappers/genderTypeToSelectOptions";
import { nationalityTypeToSelectOptions } from "utils/mappers/nationalityTypeToSelectOptions";
import { validateEmailPerVisibility } from "utils/validators/validateEmailPerVisibility";
import { Group } from "../Group";
import { PhoneNumberField } from "../PhoneNumberField";
import { EditableFieldsModel, EntityFormMode } from "../model";
import { fieldStatusResolve } from "components/Forms/fieldStatusResolve";
import { Option } from "libs/ui/Select/Select.model";

interface PersonalData {
  firstName: string;
  lastName: string;
  email?: string | null;
  phone: string;
  shortId: number;
  registryNumber?: string;
  dateOfBirth?: Date;
  gender?: GenderType;
  ancestry?: AncestryType | null;
  nationalityType?: NationalityType | null;
  status?: UserStatusType;
  years?: number;
}

type PickedValidationFieldsKeys = keyof Pick<
  PersonalData,
  "email" | "phone" | "dateOfBirth" | "registryNumber"
>;

// ! Some tests needs this
export const PersonalSectionRoles = [
  "Admin",
  "Partner",
  "Hcp",
  "Patient",
] as const satisfies readonly UserRoleType[];

interface PersonalSectionProps<T extends PersonalData>
  extends EditableFieldsModel<T>,
    EntityFormMode {
  personalData: T;
  role: (typeof PersonalSectionRoles)[number];
}

export function PersonalSection<T extends PersonalData>({
  personalData,
  role,
  editableFields,
  onChange,
  loading,
  showTitle = true,
  children,
  formMode,
}: PropsWithChildren<PersonalSectionProps<T>>) {
  const { appConfig } = useGlobalConfigContext();
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  const [registryNumberLabel] = useRegistryNumberLabel();
  const { isValidDateOfBirth } = useValidateDate();

  const formContext = formMode === "Add" ? "Add" : "Update";

  const {
    firstName,
    lastName,
    email,
    phone,
    registryNumber,
    dateOfBirth = new Date(),
    ancestry,
    gender,
    nationalityType,
    status,
    years,
    shortId,
  } = personalData;

  const emailVisibility = useMemo(
    () =>
      appConfig?.entities[role.toLowerCase() as Lowercase<UserRoleType>].email.contexts[formContext]
        .visibility,
    [appConfig?.entities, formContext, role],
  );
  const isValidEmail = validateEmailPerVisibility(emailVisibility);

  const { registryNumber: appConfigRegistry } = appConfig?.general ?? {};
  const registryField: ConfigFieldModel<string> = useMemo(
    () => ({
      label: appConfigRegistry?.registryNumberType,
      contexts: appConfigRegistry?.contexts ?? {
        Add: { visibility: "Optional", validatorTypeName: null },
        Update: { visibility: "Optional", validatorTypeName: null },
      },
      value: "",
    }),
    [appConfigRegistry?.contexts, appConfigRegistry?.registryNumberType],
  );
  const isValidRegistryNumber = useCallback(
    (val: string) => validateRegistryNumber(val, registryField, formContext),
    [formContext, registryField],
  );

  const VALIDATION_MAP = useMemo(
    () =>
      ({
        email: isValidEmail,
        phone: isValidPhoneNumber,
        dateOfBirth: isValidDateOfBirth,
        registryNumber: isValidRegistryNumber,
      }) as const,
    [isValidEmail, isValidDateOfBirth, isValidRegistryNumber],
  );

  const [validationFieldsText, setValidationFieldsText, onInputWithValidation] =
    useOnInputValidation(onChange, VALIDATION_MAP);

  const checkValidationOnBlur = useCallback(
    (inputKey: PickedValidationFieldsKeys) => {
      if (!Object.hasOwn(personalData, inputKey)) {
        return;
      }

      setValidationFieldsText(prevValue => ({
        ...prevValue,
        [inputKey]: !VALIDATION_MAP[inputKey](personalData[inputKey] as any)
          ? t(`ValidationMessages.${inputKey}`)
          : undefined,
      }));
    },
    [VALIDATION_MAP, personalData, setValidationFieldsText, t],
  );

  const onEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      const resolved = value === "" ? null : value.toLowerCase();

      onInputWithValidation(resolved, "email");
    },
    [onInputWithValidation],
  );

  const onRegistryNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      const resolved = value === "" ? null : value;

      onInputWithValidation(resolved, "registryNumber");
    },
    [onInputWithValidation],
  );

  const onSelectWithValidation = useCallback(
    (value: any, inputKey: PickedValidationFieldsKeys) => {
      onChange(value, inputKey);

      if (validationFieldsText[inputKey]) {
        setValidationFieldsText(prevValue => ({
          ...prevValue,
          [inputKey]: !VALIDATION_MAP[inputKey](value)
            ? t(`ValidationMessages.${inputKey}`)
            : undefined,
        }));
      }
    },
    [VALIDATION_MAP, onChange, setValidationFieldsText, t, validationFieldsText],
  );

  const onPhoneChange = useCallback(
    (value: string | undefined) => {
      onChange(value, "phone");
    },
    [onChange],
  );

  const getEntityFields = useMemo(() => {
    try {
      return appConfig?.entities[role.toLowerCase() as RoleEntities];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      throw new Error(`We don't have ${role.toLowerCase()} in appConfig.entities`);
    }
  }, [appConfig?.entities, role]);

  const registryNumberField = fieldStatusResolve({
    formMode,
    isReadonly: isReadonly("registryNumber", editableFields),
    field: appConfig?.general.registryNumber,
    value: registryNumber,
  });

  const ancestryTypeField = fieldStatusResolve({
    formMode,
    isReadonly: isReadonly("ancestry", editableFields),
    field: (appConfig?.entities as any)[role.toLowerCase() as RoleEntities].ancestry
      ? (appConfig?.entities as any)[role.toLowerCase() as RoleEntities].ancestry
      : undefined,
    value: ancestry,
  });

  //
  const nationalityTypeField = fieldStatusResolve({
    formMode,
    isReadonly: isReadonly("nationalityType", editableFields),
    field: getEntityFields?.nationalityType,
    value: nationalityType,
  });

  const emailTypeField = fieldStatusResolve({
    formMode,
    isReadonly: isReadonly("email", editableFields),
    field: getEntityFields?.email,
    value: email,
  });

  const setRenderSelectedNationality = useCallback(
    (props?: Option<NationalityType | string>) => (
      <SelectFilterResult
        hasValue={nationalityTypeField.showNA || !!(props ?? nationalityType)}
        label={i18n.format("nationality", "optionalField", undefined, {
          optional: nationalityTypeField.optional,
        })}
      >
        {nationalityTypeField.showNA ? t("na") : (props?.title ?? nationalityType)}
      </SelectFilterResult>
    ),
    [nationalityType, nationalityTypeField.optional, nationalityTypeField.showNA, t],
  );

  return (
    <Group>
      {showTitle ? (
        <SideModalElements.SectionTitle>{t("Subtitle.personal")}</SideModalElements.SectionTitle>
      ) : null}
      {children}
      <div className="Form__field">
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value, "firstName")
          }
          label={t("firstName")}
          value={firstName}
          data-testid="FirstName"
          readOnly={isReadonly("firstName", editableFields)}
          loading={loading}
        />
      </div>
      <div className="Form__field">
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value, "lastName")
          }
          label={t("lastName")}
          value={lastName}
          data-testid="LastName"
          readOnly={isReadonly("lastName", editableFields)}
          loading={loading}
        />
      </div>
      {role === "Patient" && registryNumberField.show ? (
        <div className="Form__field">
          <Input
            onChange={onRegistryNumberChange}
            optional={registryNumberField.optional}
            data-testid="registryNumber"
            data-type={appConfig?.general.registryNumber.registryNumberType}
            label={registryNumberLabel}
            value={registryNumberField.showNA ? t("na") : (registryNumber ?? "")}
            readOnly={registryNumberField.readonly}
            loading={loading}
            onBlur={() => checkValidationOnBlur("registryNumber")}
            validation={{ errorText: validationFieldsText.registryNumber }}
          />
        </div>
      ) : null}

      {role === "Patient" && formMode === "Readonly" ? (
        <div className="Form__field">
          <Input
            data-testid="shortId"
            label={t("shortId")}
            value={shortId}
            readOnly={true}
            loading={loading}
          />
        </div>
      ) : null}

      {Object.hasOwn(personalData, "dateOfBirth") ? (
        <div className="Form__field">
          {status === "Deleted" && Object.hasOwn(personalData, "years") ? (
            <Input label={t("years")} value={years} readOnly={true} />
          ) : (
            <DatePickerFieldLazy
              data-testid={"date-of-birth"}
              value={dateOfBirth}
              disabled={isReadonly("dateOfBirth", editableFields)}
              onSelect={(value: Date) => {
                onSelectWithValidation(value, "dateOfBirth");
              }}
              onBlur={() => checkValidationOnBlur("dateOfBirth")}
              validation={{
                errorText: validationFieldsText.dateOfBirth,
              }}
            />
          )}
        </div>
      ) : null}
      {Object.hasOwn(personalData, "gender") ? (
        <div className="Form__field">
          <Selection<GenderType | null>
            options={genderTypeToSelectOptions()}
            onSelect={(value?: GenderType | null) => onChange(value ?? "", "gender")}
            label={t("gender")}
            value={gender}
            data-testid={"gender"}
            readOnly={isReadonly("gender", editableFields)}
            loading={loading}
          />
        </div>
      ) : null}

      {ancestryTypeField.show ? (
        <div className="Form__field">
          <Selection<AncestryType | null>
            options={ancestryTypeToSelectOptions()}
            onSelect={(value?: AncestryType | null) => onChange(value ?? null, "ancestry")}
            label={t("ancestry")}
            data-testid={"ancestry"}
            value={ancestry}
            showNa={ancestryTypeField.showNA}
            readOnly={ancestryTypeField.readonly}
            loading={loading}
            optional={ancestryTypeField.optional}
          />
        </div>
      ) : null}

      {nationalityTypeField.show ? (
        <div className="Form__field">
          <SelectFilter<NationalityType>
            options={nationalityTypeToSelectOptions()}
            data-testid={"nationality"}
            value={nationalityTypeField.showNA ? (t("na") as any) : nationalityType}
            onSelect={value => onChange(value ?? undefined, "nationalityType")}
            renderSelected={setRenderSelectedNationality}
            readOnly={nationalityTypeField.readonly}
          />
        </div>
      ) : null}

      {emailTypeField.show ? (
        <div className="Form__field">
          <Input
            onChange={onEmailChange}
            label={t("email")}
            type="email"
            value={emailTypeField.showNA ? t("na") : (email ?? "")}
            optional={emailTypeField.optional}
            data-testid="Email"
            validation={{
              errorText: validationFieldsText.email,
            }}
            onBlur={() => checkValidationOnBlur("email")}
            readOnly={emailTypeField.readonly}
            loading={loading}
          />
        </div>
      ) : null}
      <div className="Form__field">
        <PhoneNumberField
          phone={phone}
          onChange={onPhoneChange}
          readOnly={isReadonly("phone", editableFields)}
          loading={loading}
        />
      </div>
    </Group>
  );
}
