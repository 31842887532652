import { UnitsModel, UserGraphModel } from "api/query";
import { ECOption, Formatters } from "components/Chart/model";
import { tooltipFormatter } from "components/Chart/tooltip";
import { mapMeasurementGraphModelToChartData } from "components/Chart/utils";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import * as echarts from "echarts/core";
import _ from "lodash-es";
import { localeNumberFormat } from "utils/formatters/localeNumberFormat";
import { RegionType } from "api/query/models";
import { getColorValue } from "utils";

export interface AreaType {
  areaColor: string;
  start?: number;
  end?: number;
  label: string;
  labelDetails?: string;
  labelColor: string;
  legendColor: string;
}

export interface YAxis {
  min?: number;
  max?: number;
  interval?: number;
}

type NormalRange = [number | undefined, number | undefined];

interface GradientColorStop {
  offset: number;
  color: string;
}

interface SingleAreaOptionsProps {
  formatters: Formatters;
  data: UserGraphModel;
  unitType: keyof UnitsModel;
  measurementUnit: MEASUREMENT_UNITS;
  yAxis?: YAxis;
  normalAreaRange?: NormalRange;
  areaStyleColorStops?: GradientColorStop[];
  showTrending?: boolean;
  // showNormalRange?: boolean;
}

export function setYAxis(yAxis: YAxis | undefined) {
  if (_.isEmpty(yAxis)) {
    return {};
  }

  return {
    yAxis: {
      ...(yAxis.min ? { min: yAxis.min } : {}),
      ...(yAxis.max
        ? {
            max: (value: { min: number; max: number }) =>
              yAxis.max && value.max < yAxis.max ? yAxis.max : Math.ceil(value.max),
          }
        : {}),
      ...(yAxis.interval ? { interval: yAxis.interval } : {}),
    },
  };
}

export function getSingleAreaOptions({
  formatters,
  data,
  unitType,
  measurementUnit,
  yAxis,
  normalAreaRange,
  areaStyleColorStops,
  showTrending,
  // showNormalRange,
}: SingleAreaOptionsProps) {
  return {
    visualMap: [
      {
        show: false,
        ...(normalAreaRange?.length
          ? {
              type: "piecewise",
              dimension: 1,
              pieces: [
                {
                  min: normalAreaRange[0] ?? 0,
                  max: normalAreaRange[1] ?? Number.MAX_SAFE_INTEGER,
                  color: getColorValue("--green-500"),
                },
              ],

              outOfRange: {
                color: getColorValue("--grey-500"),
              },
            }
          : {
              type: "continuous",
              dimension: 0,
              target: {
                inRange: {
                  color: [getColorValue("--secondary-500"), getColorValue("--grey-500")],
                },
              },
            }),
      },
    ],
    tooltip:
      data.measurements.length > 0
        ? {
            backgroundColor: "transparent",
            borderWidth: 0,
            trigger: "axis",
            axisPointer: {
              type: "line",
              lineStyle: { color: "#9CA3AF" },
              z: 2,
            },
            triggerOn: "mousemove",
            padding: 0,
            borderRadius: 8,
            shadowColor: "transparent",
            formatter: (params: any) =>
              tooltipFormatter(params, formatters, data, unitType, measurementUnit, showTrending),
            z: 3,
            confine: true,
          }
        : undefined,
    grid: {
      left: 40,
      top: 15,
      right: 20,
      bottom: 90,
    },
    ...setYAxis(yAxis),
    series: [
      {
        name: unitType,
        zlevel: 1,
        type: "line",
        showSymbol: true,
        symbolSize: 8, // single node size in pixels
        clip: true,
        data: mapMeasurementGraphModelToChartData(data.measurements, unitType, measurementUnit),
        emphasis: {
          scale: 2, // scale single node on hover
        },
        ...(areaStyleColorStops
          ? {
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [...areaStyleColorStops]),
              },
            }
          : undefined),
      },
      ...(normalAreaRange?.length
        ? [
            {
              z: 1,
              type: "line",
              markArea: {
                silent: true,
                data: [
                  [
                    ...normalAreaRange.map(value => ({
                      yAxis: value ?? 0,
                      itemStyle: {
                        color: "#ECFDF5",
                        opacity: 0.6,
                      },
                    })),
                  ],
                ],
              },
              markLine: {
                label: {
                  position: "start",
                  color: "#10B981",
                  fontSize: 11,
                  opacity: 1,
                },
                silent: true,
                symbol: "none",
                lineStyle: {
                  color: "#10B981",
                  opacity: 0.8,
                },
                data: [
                  ...normalAreaRange.map(value => ({
                    yAxis: value ?? 0,
                    label: {
                      show: !!value && value !== 0,
                    },
                  })),
                ],
              },
            },
          ]
        : []),
    ],
  } as ECOption;
}

interface GetAreaColoredOptionsProps {
  formatters: Formatters;
  data: UserGraphModel;
  yAxis?: YAxis;
  areas: AreaType[];
  unitType: Exclude<keyof UnitsModel, "bloodPressure">;
  measurementUnit?: MEASUREMENT_UNITS;
  markLineRanges?: number[];
  showTrending?: boolean;
  precision?: number;
  regionType?: RegionType;
}

function getLegendLabel(
  start: number | undefined,
  end: number | undefined,
  isLastElement: boolean,
  regionType: RegionType,
) {
  const lastElementEquationSymbol = isLastElement ? "≥ " : "";
  const fromPart = !Number.isNaN(start)
    ? localeNumberFormat(Number(start), regionType, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        roundingMode: "trunc",
      })
    : "";
  const toPart =
    end === Number.MAX_SAFE_INTEGER || end === undefined
      ? ""
      : " - " +
        localeNumberFormat(end, regionType, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
          roundingMode: "trunc",
        });

  return lastElementEquationSymbol + fromPart + " " + toPart;
}

export function getAreaColoredOptions({
  formatters,
  data,
  yAxis,
  areas,
  unitType,
  measurementUnit,
  markLineRanges,
  showTrending,
  precision,
  regionType = "en-US",
}: GetAreaColoredOptionsProps): ECOption {
  return {
    visualMap: {
      orient: "horizontal",
      top: 0,
      right: 15,
      pieces: [
        ...areas.map((area, i) => ({
          gte: area.start,
          max: i === areas.length - 1 ? undefined : area.end,
          color: area.legendColor,
          label: getLegendLabel(area.start, area.end, i === areas.length - 1, regionType),
        })),
      ],
      precision: precision,
      outOfRange: {
        color: "#e5e7eb",
      },
    },
    tooltip:
      data.measurements.length !== 0
        ? {
            backgroundColor: "transparent",
            borderWidth: 0,
            trigger: "axis",
            axisPointer: {
              type: "line",
              lineStyle: { color: "#9CA3AF" },
              z: 2,
            },
            triggerOn: "mousemove",
            padding: 0,
            borderRadius: 8,
            shadowColor: "transparent",
            formatter: (params: any) =>
              tooltipFormatter(params, formatters, data, unitType, measurementUnit, showTrending),
            z: 5,
            confine: true,
          }
        : undefined,
    grid: {
      left: 30,
      top: 40, // making enough space for the visualMap.pieces (legend)
      right: 20,
      bottom: 90,
    },
    ...setYAxis(yAxis),
    series: [
      {
        name: unitType,
        type: "line",
        showSymbol: true,
        symbolSize: 8, // single node size in pixels
        data: mapMeasurementGraphModelToChartData(data.measurements, unitType, measurementUnit),
        emphasis: {
          scale: 2, // scale single node on hover
        },
      },
      ...areas.map(area => {
        return {
          type: "line",
          markArea: {
            z: 1,
            name: area.label,
            ...(data.measurements.length > 0 && {
              label: {
                show: true,
                position: "inside",
                formatter: function () {
                  const resolveMarkAreaLabel = () => {
                    if (!area.labelDetails) {
                      return `{firstPart|${area.label}}`;
                    }

                    return `{firstPart|${area.label}} {secondPart|${area.labelDetails}}`;
                  };
                  return resolveMarkAreaLabel();
                },
                rich: {
                  firstPart: {
                    color: area.labelColor,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 14,
                  },
                  secondPart: {
                    color: area.labelColor,
                    fontFamily: "Poppins",
                    fontSize: 0,
                  },
                },
              },
            }),
            emphasis: {
              label: {
                position: "inside",
                rich: {
                  secondPart: { fontSize: 14 },
                },
              },
              itemStyle: { color: area.areaColor, opacity: 0.7 },
            },
            data: [
              [
                {
                  yAxis: area.end,
                  itemStyle: {
                    color: area.areaColor,
                    opacity: 0.7,
                  },
                },
                {
                  yAxis: area.start,
                  itemStyle: {
                    color: area.areaColor,
                    opacity: 0.7,
                  },
                },
              ],
            ],
          },
          ...(markLineRanges && markLineRanges?.length > 0
            ? {
                markLine: {
                  label: {
                    position: "start",
                    color: "#10B981",
                    fontSize: 11,
                    opacity: 1,
                  },
                  silent: true,
                  symbol: "none",
                  lineStyle: {
                    color: "#10B981",
                    opacity: 0.8,
                  },
                  data: [
                    ...markLineRanges.map(value => ({
                      yAxis: value,
                      label: {
                        show: value !== 0,
                      },
                    })),
                  ],
                },
              }
            : undefined),
        };
      }),
    ],
  } as ECOption;
}
